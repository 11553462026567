.sponsors {
}

.logos-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  .logos__item {
    flex-shrink: 0;
    flex-basis: 20%;
    padding: 3rem 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 70px;
    }
  }
}

@media (max-width: 768px) {

  .sponsors {

    .wrapper {
      width: 100%;
      margin-left: 0;
    }
  }

  .logos-list {

    .logos__item {
      flex-shrink: 0;
      flex-basis: 50%;
      padding: 3rem 2rem;
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }
}
