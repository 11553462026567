.label {
  font-weight: 600;
  display: inline-block;

  &.label--circle {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.m {
      width: 25px;
      height: 25px;
    }
  }

  &.shadow {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .1);
  }

  &.label--s {
    padding: .2rem .5rem !important;
    font-size: .8rem !important;
    border-radius: 4px;
  }

  &.label--m {
    padding: .4rem 1rem !important;
    font-size: 1rem !important;
    border-radius: 6px;
  }

  &.label--l {
    padding: .3rem 1.5rem !important;
    font-size: 1.5rem !important;
    font-weight: 800 !important;
    border-radius: 10px;
  }

  &.label--success {
    background: $color-success;
    color: $color-white;
    border: 1px solid $color-success;
  }

  &.label--danger {
    background: $color-danger;
    color: $color-white;
    border: 1px solid $color-danger;
  }

  &.label--danger-inv {
    background: $color-danger-light;
    color: $color-danger-dark;
    border: 1px solid $color-danger-light;
  }

  &.label--default {
    background: transparent;
    color: $color-bright;
    border: 1px solid $color-light-gray;
  }

  &.label--white {
    background: $color-white;
    color: $color-light-gray;
  }

}
