.error-page {
  width: 100vw;
  height: 100vh;
  background: $body-bg;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 6rem;
  }

  h2 {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  p {
    margin-bottom: 3rem;
    line-height: 2rem;
    font-size: 1.4rem;
    font-weight: 400;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    width: 150px;
  }

  i {
    font-size: 30rem;
    color: #333;
  }
}