.conference-header {
  min-height: 80px;
  max-height: 1080px;
  padding: 0 !important;
  background: $color-white;
  overflow: hidden;
  position: relative;
  margin-bottom: 5rem;

  .header__nav {
    background: rgba(255, 255, 255, .98);
    border-bottom: 1px solid $color-bright;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;

    &.nav--bg {
      background: rgba(255, 255, 255, .98);
      border-bottom: 1px solid $color-bright;

      a {
        color: $color-black !important;
      }

      .logo__dark {
        display: block;
      }

      .logo__white {
        display: none;
      }

      .social-icons {

        &.white {
          display: none !important;
        }

        &.dark {
          display: flex !important;
        }
      }
    }

    .logo__dark {
      display: none;
    }

    .logo__white {
      display: block;
    }

    .social-icons {

      &.white {
        display: flex !important;
      }

      &.dark {
        display: none !important;
      }
    }

    .header__nav__inner {
      display: flex;
      justify-content: space-between;
      height: 80px;
      padding: 0;

      .nav__column {

        .nav__list {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;

          .nav__item {

            a {
              font-weight: 700;
              font-size: 1.6rem;
              padding: 0 2rem;
              color: $color-black;
              text-transform: uppercase;
              text-decoration: none;

              &:hover {
                text-decoration: none;
              }
            }
          }
        }

        &.logo {
          padding: 2rem 0;

          img {
            height: 100%;
          }
        }

        &.back {
          display: flex;
          align-items: center;

          .nav__list {
            margin-left: 0;

            a {
              height: 100%;
              display: flex;
              align-items: center;
              font-size: 1.4rem;

              img {
                margin-right: 2rem;
                width: 20px;
              }
            }
          }
        }

        &.navbar {
          display: flex;
          align-items: center;
          margin-right: 11rem;

          .nav__list {
            height: 100%;

            .nav__item {


              a {
                height: 100%;
                display: flex;
                align-items: center;
              }

              &.active {
                box-shadow: inset 0 -4px 0 $color-secondary;
              }

              &:hover {
                box-shadow: inset 0 -4px 0 $color-secondary;
              }
            }
          }
        }

        &.social {

          display: flex;
          align-items: center;

          .social-icons {
            display: flex;

            .social__icon {
              margin-right: 1rem;

              img {
                width: 30px;
              }

              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  .header__content {
    position: relative;
    z-index: 1;
    width: 60%;
    height: 100vh;
    max-height: 1080px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header-read__more {
      position: absolute;
      left: 0;
      bottom: 60px;
      display: flex;
      align-items: center;
      color: $color-black;
      text-decoration: none;

      &:after {
        content: '';
        width: 1px;
        height: 60px;
        background: $color-bright;
        position: absolute;
        left: 17px;
        top: 34px;
        z-index: 0;
      }

      img {
        margin-right: 1rem;
      }

      p {
        font-size: 1.4rem;

        &:hover {
          text-decoration: underline;
        }

      }
    }

    .header-tags-list {
      margin-bottom: 2rem;

      .header-tags__item {
        font-size: 1.6rem;
        text-transform: uppercase;
      }
    }

    h1 {
      position: relative;
      margin-bottom: 7rem;
      width: 100%;
      font-size: 4.8rem;
      text-transform: uppercase;

      &:after {
        content: '';
        height: 34px;
        width: 34px;
        border-radius: 50%;
        background: $color-secondary;
        position: absolute;
        left: 0;
        bottom: -5rem;
      }
    }

    .header__date {
      font-size: 2.8rem;
      margin-bottom: 1rem;
      font-weight: 700;
    }

    .header__place {
      font-size: 1.6rem;
      margin-bottom: 6rem;
    }

    a {
      align-self: flex-start;
    }
  }

  .header-img--bg {
    position: absolute;
    right: -55%;
    bottom: 30px;
    z-index: 0;
  }


}

@media (max-width: 768px) {

  .conference-header {
    margin-bottom: 0;

    .header__nav {
      background: $color-white;
      border-bottom: 1px solid $color-bright;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 99999;

      .header__nav__inner {
        display: flex;
        justify-content: space-between;
        height: 60px;
        padding: 1rem 0;

        .nav__group {
          justify-content: space-between;
        }

        .nav__column {

          .nav__list {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;

            .nav__item {

              a {
                font-weight: 700;
                font-size: 1.4rem;
                padding: 0 1rem;
                color: $color-black;
                text-transform: uppercase;
                text-decoration: none;

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }

          &.logo {
            padding: .5rem 0;
            width: 150px;

            img {
              height: 100%;
            }
          }

          &.back {
            display: flex;
            align-items: center;

            .nav__list {
              margin-left: 1rem;

              a {
                display: flex;
                align-items: center;

                p {
                  opacity: .3;
                }

                img {
                  margin-right: 2rem;
                  width: 20px;
                }
              }
            }
          }

          &.navbar {
            display: flex;
            align-items: center;
            margin-right: 0;
            position: absolute;
            top: 60px;
            left: 0;
            width: 100%;
            background: $color-light;
            border-bottom: 1px solid $color-bright;

            .nav__list {
              width: 100%;
              justify-content: space-between;
              padding: 0 1rem;

              li {
                padding: 1rem 0;
              }
            }
          }

          &.social {

            display: flex;
            align-items: center;

            .social-icons {
              display: flex;

              .social__icon {
                margin-right: 1rem;

                &:last-of-type {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }


    }

    .header__content {
      width: 100%;
      height: 80vh;
      margin-top: 7rem;

      .header-read__more {
        display: none;
      }

      .header-tags-list {
        margin-bottom: 2rem;

        .header-tags__item {
          font-size: 2rem;
        }
      }

      h1 {
        margin-bottom: 4rem;
        font-size: 3.2rem;

        &:after {
          height: 24px;
          width: 24px;
          bottom: -3rem;
        }
      }

      .header__date {
        font-size: 2.6rem;
      }

      .header__place {
        font-size: 2rem;
        margin-bottom: 4rem;
      }
    }

    .header-img--bg {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
      width: 200px;
    }


  }
}
