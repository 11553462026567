.faq-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10rem;

  .faq__item {
    display: flex;
    width: 100%;
    padding: 2rem 0;
    border-bottom: 1px solid $color-bright;

    &:first-of-type {
      padding-top: 0;
    }

    .faq__column {

      &:first-of-type {
        flex-basis: 80%;
      }

      &:last-of-type {
        flex-basis: 20%;
        display: flex;
        justify-content: flex-end;
      }
    }

    h3 {
      font-size: 1.6rem;
      margin-bottom: 0;
      text-align: left;
    }

    .faq__content {
      height: 20px;
      overflow: hidden;

      p {
        font-size: 1.6rem;
      }

      &.opened {
        height: 100%;
      }
    }
  }
}

@media (max-width: 768px) {

  .faq-container {
    margin-left: 0;

    .faq__item {

      h3 {
        font-size: 1.4rem;
      }

      .faq__content {
        height: 20px;

        p {
          font-size: 1.4rem;
        }
      }
    }
  }
}
