@charset "UTF-8";
#flashes.alert {
  position: fixed;
  width: 40rem;
  top: -6rem;
  right: calc(50% - 20rem);
  z-index: 99;
  animation: flash-animation 5s;
  animation-fill-mode: forwards;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  opacity: 1;
  text-align: center;
}

.alert {
  padding: 2rem;
  border-radius: 5px;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 5rem;
}
.alert a {
  color: #fff;
  font-weight: 600;
  text-decoration: underline;
}
.alert a:hover {
  text-decoration: none;
}
.alert.alert--ml {
  margin-left: 10rem;
}
.alert.alert--danger {
  background: #EF4444;
  color: #fff;
}
.alert.alert--success {
  background: #10B981;
  color: #fff;
}
.alert.alert--warning {
  background: #FEC601;
  color: #fff;
}

@keyframes flash-animation {
  0% {
    opacity: 0;
    top: -6rem;
  }
  15% {
    opacity: 1;
    top: 1rem;
  }
  85% {
    opacity: 1;
    top: 1rem;
  }
  100% {
    opacity: 0;
    top: -6rem;
  }
}
.fixed-alert {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1rem 3rem;
  background: #E50056;
}
.fixed-alert p {
  color: #fff;
  margin-bottom: 0 !important;
}
.fixed-alert.auth {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

#CybotCookiebotDialog {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}
#CybotCookiebotDialog h2, #CybotCookiebotDialog p, #CybotCookiebotDialog a, #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText {
  font-family: "Montserrat", sans-serif !important;
}

#CybotCookiebotDialogBody {
  max-width: 1140px !important;
  padding: 0 2rem !important;
}
#CybotCookiebotDialogBody #CybotCookiebotDialogPoweredbyLink {
  display: none;
}
#CybotCookiebotDialogBody #CybotCookiebotDialogBodyContent {
  padding-left: 0 !important;
  padding-top: 20px;
}
#CybotCookiebotDialogBody #CybotCookiebotDialogBodyButtons {
  margin-left: 0 !important;
  padding-left: 0 !important;
}
#CybotCookiebotDialogBody .CybotCookiebotDialogBodyButton {
  margin-left: 0 !important;
  border-radius: 4px !important;
  padding: 5px 10px !important;
  border: none;
  background: #1e3953 !important;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  background: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  padding: 0;
  margin: 0;
}
body h1, body h2, body h3, body h4, body p {
  margin: 0;
}
body a {
  text-decoration: none;
}

.wrapper {
  padding: 0 2rem;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.wrapper .wrapper__column {
  flex-basis: 50%;
  flex-shrink: 0;
}
.wrapper .wrapper__column:first-of-type {
  padding-right: 5rem;
}
.wrapper .wrapper__column:last-of-type {
  padding-left: 5rem;
}
.wrapper.line:after {
  content: "";
  width: 1px;
  height: calc(100% + 181px);
  background: #E6E6E6;
  position: absolute;
  left: calc(2rem + 17px);
  top: calc(-5rem - 81px);
  z-index: 0;
}

@media (max-width: 768px) {
  body h1 {
    font-size: 3rem;
  }

  .wrapper {
    padding: 0 2rem;
  }
  .wrapper .wrapper__column {
    flex-basis: 100%;
  }
  .wrapper .wrapper__column:first-of-type {
    padding-right: 0;
  }
  .wrapper .wrapper__column:last-of-type {
    margin-top: 3rem;
    padding-left: 0;
  }
  .wrapper.line:after {
    width: 0 !important;
  }

  .contact-form.register {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
.btn---desc {
  font-size: 1.2rem;
  font-style: italic;
  margin-top: 1rem;
  display: inline-block;
}

.btn, button {
  font-weight: 600;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  border: none;
  cursor: pointer;
  position: relative;
}
.btn:focus, button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.btn.btn--circle, button.btn--circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.btn.btn--circle.s, button.btn--circle.s {
  width: 20px;
  height: 20px;
}
.btn.btn--primary, button.btn--primary {
  background: #1e3953;
  color: #fff !important;
  transition: all 0.2s;
}
.btn.btn--primary:hover, button.btn--primary:hover {
  background: #FEC601;
}
.btn.btn--secondary, button.btn--secondary {
  background: #FEC601;
  color: #fff;
  transition: all 0.2s;
}
.btn.btn--secondary:hover, button.btn--secondary:hover {
  background: #e5b201;
}
.btn.btn--white, button.btn--white {
  color: #4D4D4D;
  background: #fff;
  transition: all 0.2s;
}
.btn.btn--white:hover, button.btn--white:hover {
  background: #f7f7f7;
}
.btn.btn--success, button.btn--success {
  color: #fff;
  background: #10B981;
  transition: all 0.2s;
}
.btn.btn--success:hover, button.btn--success:hover {
  background: #0fab77;
}
.btn.btn--success-inv, button.btn--success-inv {
  background: #A7F3D0;
  color: #064E3B;
  transition: all 0.2s;
}
.btn.btn--success-inv:hover, button.btn--success-inv:hover {
  background: #9af1c9;
}
.btn.btn--danger, button.btn--danger {
  color: #fff;
  background: #EF4444;
  transition: all 0.2s;
}
.btn.btn--danger:hover, button.btn--danger:hover {
  background: #ee3636;
}
.btn.btn--danger-inv, button.btn--danger-inv {
  background: #FECACA;
  color: #7F1D1D;
  transition: all 0.2s;
}
.btn.btn--danger-inv:hover, button.btn--danger-inv:hover {
  background: #febbbb;
}
.btn.btn--shadow, button.btn--shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}
.btn.btn--text, button.btn--text {
  background: transparent;
  color: #000;
}
.btn.btn--text:hover, button.btn--text:hover {
  text-decoration: underline;
}
.btn.btn--text.gray, button.btn--text.gray {
  color: #4D4D4D;
}
.btn.btn--text.primary, button.btn--text.primary {
  color: #1e3953;
}
.btn.btn--text.danger, button.btn--text.danger {
  color: #EF4444;
}
.btn.btn--text.p--0, button.btn--text.p--0 {
  padding: 0;
}
.btn.btn--text.xs, button.btn--text.xs {
  font-size: 1.2rem;
}
.btn.btn--l, button.btn--l {
  font-size: 2.2rem;
  padding: 1.6rem 4rem;
}
.btn.btn--s, button.btn--s {
  font-size: 1.6rem;
  padding: 1.4rem 3.6rem;
}
.btn.btn--xs, button.btn--xs {
  font-size: 1.3rem;
  padding: 0.8rem 1rem;
  text-align: center;
}
.btn.btn--xs.fixed, button.btn--xs.fixed {
  width: 130px;
}
.btn.btn--more, button.btn--more {
  height: 32px;
  width: 32px;
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.btn--conferences, button.btn--conferences {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
}
.btn.btn--conferences img, button.btn--conferences img {
  margin: 0 !important;
}

@media (max-width: 768px) {
  .btn.btn--l {
    font-size: 2rem;
    padding: 1rem 3rem;
  }
  .btn.btn--s {
    font-size: 1.6rem;
    padding: 1.2rem 3rem;
  }
}
footer {
  background: #000;
  color: #fff;
  padding: 5rem 0;
}
footer a {
  color: #fff;
}
footer .footer-container .footer__top {
  display: flex;
  flex-wrap: wrap;
}
footer .footer-container .footer__bottom {
  margin-top: 2rem;
  text-align: center;
}
footer .footer__column {
  flex-grow: 1;
  flex-basis: 0;
}
footer .footer__column.footer__logo .footer__logo__inner {
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  width: 60%;
}
footer .footer__column.footer__logo img {
  height: auto;
  width: 100%;
}
footer .footer__column.footer__menu {
  margin-right: 3rem;
}
footer .footer__column.footer__menu .menu__list {
  display: flex;
  flex-direction: column;
}
footer .footer__column.footer__menu .menu__list .menu__item {
  margin-bottom: 0.4rem;
}
footer .footer__column.footer__menu .menu__list .menu__item a {
  text-decoration: none;
  font-size: 1.4rem;
}
footer .footer__column.footer__menu .menu__list .menu__item a:hover {
  text-decoration: underline;
}
footer .footer__column.footer__contact {
  display: flex;
  flex-direction: column;
}
footer .footer__column.footer__contact .contact__item {
  display: flex;
  align-items: flex-start;
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-bottom: 1rem;
  padding-bottom: 3.1rem;
}
footer .footer__column.footer__contact .contact__item a {
  text-decoration: none;
}
footer .footer__column.footer__contact .contact__item a:hover {
  text-decoration: underline;
}
footer .footer__column.footer__contact .contact__item p {
  line-height: 2.2rem;
  font-size: 1.4rem;
}
footer .footer__column.footer__contact .contact__item .item__img {
  width: 45px;
}
footer .footer__column.footer__contact .contact__item .item__img img {
  margin-right: 2rem;
}
footer .footer__column.footer__contact .footer__logos {
  padding: 5px;
  border-radius: 4px;
  background: #fff;
  height: 34px;
  align-self: flex-start;
}
footer .footer__column.footer__contact .footer__logos img {
  height: 24px;
}
footer .footer__column.footer__social .social__list {
  display: flex;
  margin-bottom: 1rem;
}
footer .footer__column.footer__social .social__list .social__icon {
  margin-right: 1rem;
}
footer .footer__column.footer__social .social__list .social__icon:last-of-type {
  margin-right: 0;
}
footer .footer__column.footer__social .social__list .social__icon img {
  width: 30px;
}
footer .footer__column.footer__social a {
  text-decoration: none;
}

.under-construction {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background-color: #EF4444;
  color: #fff;
  z-index: 99999;
}
.under-construction p {
  margin-bottom: 10px;
  font-weight: 700;
}
.under-construction p:last-of-type {
  margin-bottom: 0;
}
.under-construction a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  footer {
    padding: 4rem 0;
  }
  footer .footer-container {
    flex-direction: column;
  }
  footer .footer__column.footer__logo {
    flex-basis: 100%;
  }
  footer .footer__column.footer__menu {
    margin-top: 3rem;
    flex-basis: 100%;
  }
  footer .footer__column.footer__menu .menu__list .menu__item {
    flex: 1 1 50%;
  }
  footer .footer__column.footer__menu .menu__list .menu__item a {
    font-size: 1.4rem;
  }
  footer .footer__column.footer__contact {
    margin-left: 0;
    margin-top: 3rem;
    flex-direction: column;
  }
  footer .footer__column.footer__contact .contact__item {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
  footer .footer__column.footer__contact .contact__item p {
    line-height: 2.4rem;
  }
  footer .footer__column.footer__contact .contact__item .item__img img {
    margin-right: 2rem;
  }
}
section {
  padding: 6rem 0;
  position: relative;
}
section.conf {
  padding-top: calc(80px + 5rem);
  margin-top: -80px;
  padding-bottom: 5rem;
  position: relative;
}
section h2 {
  font-size: 3rem;
  margin-bottom: 5rem;
  position: relative;
  text-transform: uppercase;
}
section h2.text--center {
  margin-left: 0 !important;
}
section h2.dot {
  margin: 0 0 8rem 10rem;
}
section h2.dot.dot--bottom:after {
  content: "";
  width: 34px;
  height: 34px;
  border-radius: 50%;
  position: absolute;
  bottom: -5rem;
  left: calc(50% - 17px);
}
section h2.dot.dot--left:after {
  content: "";
  width: 34px;
  height: 34px;
  border-radius: 50%;
  position: absolute;
  left: -10rem;
  top: 0;
  z-index: 1;
}
section h2.dot.dot--primary:after {
  background: #1e3953;
}
section h2.dot.dot--secondary:after {
  background: #FEC601;
}
section h3 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 6rem;
  font-weight: 600;
}
section h3.heading--icon {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  text-transform: uppercase;
  margin-bottom: 5rem;
}
section h3.heading--icon span {
  font-size: 18px;
  text-transform: uppercase;
}
section h3.heading--icon img {
  height: 25px;
  width: 25px;
  margin-right: 1rem;
}
section .subheading {
  font-size: 1.6rem;
  margin: 0 0 7rem 10rem;
}
section .subheading.text--center {
  margin-left: 0 !important;
}
section.white {
  background: #fff;
  color: #000;
}
section.white.bb {
  border-bottom: 1px solid #E6E6E6;
}
section.light {
  background: #F4F4F4;
  color: #000;
}
section.bright {
  background: #E6E6E6;
  color: #000;
}
section.transparent {
  background: transparent;
  color: #000;
}
section.img {
  position: relative;
  color: #fff;
  z-index: 0;
}
section.img:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
section.img .img--bg {
  z-index: -1;
}

@media (max-width: 768px) {
  section {
    padding: 6rem 0;
  }
  section.conf {
    padding: calc(80px + 4rem) 0;
  }
  section h2 {
    font-size: 2.4rem;
  }
  section h2.dot {
    margin-left: 6rem;
  }
  section h2.dot.dot--left:after {
    width: 24px;
    height: 24px;
    left: -6rem;
  }
  section h2.dot.dot--bottom:after {
    width: 24px;
    height: 24px;
    bottom: -3.2rem;
    left: calc(50% - 12px);
  }
  section .subheading {
    font-size: 1.4rem;
    margin-left: 6rem;
    margin-bottom: 6rem;
  }
}
.conf-about {
  display: flex;
  flex-wrap: wrap;
}
.conf-about .about__featured {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10rem;
  margin-bottom: 10rem;
  width: 100%;
}
.conf-about .about__featured .about__featured-item {
  flex: 1 0 25%;
  text-align: center;
}
.conf-about .about__featured .about__featured-item .about__featured-icon i {
  color: #1e3953;
  font-size: 6rem;
}
.conf-about .about__featured .about__featured-item .about__featured-desc {
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 2rem;
}
.conf-about .about__desc {
  padding-left: 10rem;
}
.conf-about .about__desc h3 {
  font-size: 3rem;
  margin-bottom: 1.4rem;
  text-align: left;
}
.conf-about .about__desc p, .conf-about .about__desc li {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
.conf-about .about__desc p:last-of-type, .conf-about .about__desc li:last-of-type {
  margin-bottom: 0;
}

.hp-about {
  border-bottom: 1px solid #E6E6E6;
}
.hp-about .about__featured {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.hp-about .about__featured .about__featured-item {
  padding: 3rem;
  flex: 1 0 25%;
  text-align: center;
  border-right: 1px solid #E6E6E6;
}
.hp-about .about__featured .about__featured-item:last-of-type {
  border-right: none;
}
.hp-about .about__featured .about__featured-item h3 {
  font-size: 4.8rem;
  font-weight: 700;
  margin-bottom: 3rem;
  position: relative;
}
.hp-about .about__featured .about__featured-item h3:after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #FEC601;
}
.hp-about .about__featured .about__featured-item p {
  font-size: 1.6rem;
}

@media (max-width: 768px) {
  .conf-about .about__featured {
    padding-left: 0;
    margin-bottom: 5rem;
    width: 100%;
  }
  .conf-about .about__featured .about__featured-item {
    flex: 1 0 50%;
    margin-bottom: 2rem;
  }
  .conf-about .about__featured .about__featured-item .about__featured-icon img {
    width: 20%;
  }
  .conf-about .about__featured .about__featured-item .about__featured-desc {
    font-size: 1.4rem;
  }
  .conf-about .about__desc {
    padding-left: 0;
  }
  .conf-about .about__desc h3 {
    font-size: 2.4rem;
    margin-bottom: 0.4rem;
  }
  .conf-about .about__desc p {
    font-size: 1.6rem;
  }

  .hp-about .about__featured .about__featured-item {
    border-right: none;
    flex-basis: 100%;
  }
  .hp-about .about__featured .about__featured-item h3 {
    font-size: 5.4rem;
    font-weight: 700;
    margin-bottom: 3rem;
    position: relative;
  }
  .hp-about .about__featured .about__featured-item p {
    font-size: 2rem;
  }
}
.courses {
  padding-top: calc(80px + 5rem);
  margin-top: -80px;
  padding-bottom: 5rem;
}

.courses-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4rem;
}
.courses-filter .course__filter ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}
.courses-filter .course__filter ul li {
  margin-right: 2rem;
}
.courses-filter .course__filter ul li a {
  color: #000;
  font-weight: 600;
}
.courses-filter .course__filter ul li.active a {
  color: #FEC601;
}
.courses-filter .course__filter ul li:last-of-type {
  margin-right: 0;
}
.courses-filter .courses__sort {
  display: flex;
  align-items: center;
}
.courses-filter .courses__sort .sort__item {
  display: flex;
  font-weight: 600;
  padding: 1rem 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
.courses-filter .courses__sort .sort__item:first-of-type {
  margin-right: 2rem;
}
.courses-filter .courses__sort .sort__item span {
  margin-left: 1rem;
}
.courses-filter .courses__sort .sort__item .sort__arrows button {
  background: transparent;
  padding: 0 4px;
}
.courses-filter .courses__sort .sort__item .sort__arrows i {
  font-size: 1.2rem;
}
.courses-filter .courses__sort .sort__item .sort__arrows .active {
  color: #FEC601;
}

.courses-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.courses-list.hp {
  margin-bottom: 4rem;
}
.courses-list .course__item {
  background: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  flex-basis: calc(33.3% - 1.4rem);
  margin-right: 2.1rem;
  margin-bottom: 3rem;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s;
  text-decoration: none;
  position: relative;
  padding-bottom: 48px;
}
.courses-list .course__item.archived {
  opacity: 0.5;
  filter: grayscale(100%);
}
.courses-list .course__item p, .courses-list .course__item h3, .courses-list .course__item h4, .courses-list .course__item h5 {
  color: #000;
  margin: 0;
  text-align: left;
}
.courses-list .course__item:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.25);
}
.courses-list .course__item:nth-of-type(3n) {
  margin-right: 0;
}
.courses-list .course__item .course__label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-weight: 700;
  text-transform: uppercase;
}
.courses-list .course__item .course__label span {
  padding: 0.6rem;
  color: #fff;
  display: inline-block;
  font-size: 1.2rem;
}
.courses-list .course__item .course__label.primary {
  background: #1e3953;
}
.courses-list .course__item .course__label.secondary {
  background: #FEC601;
}
.courses-list .course__item .course__label.tertiary {
  background: #E50056;
}
.courses-list .course__item .course__img {
  height: 240px;
  overflow: hidden;
  position: relative;
}
.courses-list .course__item .course__img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.courses-list .course__item .course__img .course__type {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 44px;
  display: flex;
  padding: 0 2.4rem;
  align-items: center;
  z-index: 1;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  background-image: linear-gradient(rgba(0, 0, 0, 0), black);
}
.courses-list .course__item .course__img .course__type span {
  color: #fff;
}
.courses-list .course__item .course__more {
  background: #F4F4F4;
  color: #1e3953;
  padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  font-size: 1.4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 48px;
}
.courses-list .course__item .course__more img {
  margin-left: 1rem;
  width: 16px;
}
.courses-list .course__item .course__location {
  display: flex;
  padding: 2rem 2.5rem 2rem 2.5rem;
}
.courses-list .course__item .course__location .course__top-left {
  margin-right: 3rem;
}
.courses-list .course__item .course__location .course__top-left, .courses-list .course__item .course__location .course__top-right {
  display: flex;
  align-items: center;
}
.courses-list .course__item .course__location .course__top-left img, .courses-list .course__item .course__location .course__top-right img {
  height: 16px;
  width: auto;
  margin-right: 1rem;
}
.courses-list .course__item .course__location .course__date, .courses-list .course__item .course__location .course__city {
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
}
.courses-list .course__item .course__name {
  padding: 0 2.5rem 2rem 2.5rem;
  border-bottom: 1px solid #F4F4F4;
  height: 65px;
}
.courses-list .course__item .course__name h3 {
  font-size: 1.8rem;
  font-weight: 700;
}
.courses-list .course__item .course-prices {
  display: flex;
  justify-content: space-around;
}
.courses-list .course__item .course__price {
  padding: 2rem 0rem 2rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.courses-list .course__item .course__price .price__type {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #A0A0A0;
  margin-bottom: 1rem;
}
.courses-list .course__item .course__price h4 {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  margin-bottom: 0.4rem;
  position: relative;
}
.courses-list .course__item .course__price h4 .euro {
  padding-left: 0.8rem;
}
.courses-list .course__item .course__price h4 .price__discount-value {
  padding: 0.2rem;
  background: #E50056;
  color: #fff;
  position: absolute;
  top: -8px;
  right: -28px;
  font-weight: 600;
  border-radius: 3px;
  font-size: 1rem;
}
.courses-list .course__item .course__price h5 {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 400;
}
.courses-list .course__item .course-tags-list {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.courses-list .course__item .course-tags-list .course-tag__item {
  font-size: 1.6rem;
  position: relative;
  margin-right: 25px;
}
.courses-list .course__item .course-tags-list .course-tag__item:last-of-type:after {
  display: none;
}
.courses-list .course__item .course-tags-list .course-tag__item:after {
  content: "&";
  position: absolute;
  right: -20px;
  top: 0;
}

@media (max-width: 768px) {
  .courses {
    padding: 6rem 0;
    margin-top: unset;
  }
  .courses.hp {
    padding-top: calc(80px + 4rem);
    padding-bottom: 2rem;
  }
  .courses.hp h2 {
    margin-bottom: 5rem;
  }

  .courses-filter .course__filter {
    margin-bottom: 2rem;
  }
  .courses-filter .course__filter ul {
    justify-content: space-between;
  }
  .courses-filter .course__filter ul li {
    font-size: 1rem;
    margin-right: 0;
  }
  .courses-filter .courses__sort .sort__item {
    align-items: center;
  }
  .courses-filter .courses__sort .sort__item span {
    font-size: 1rem;
    margin-left: 0.4rem;
  }
  .courses-filter .courses__sort .sort__item .sort__arrows {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .courses-filter .courses__sort .sort__item .sort__arrows i {
    font-size: 1rem;
  }

  .courses-list .course__item {
    padding: 0;
    flex-basis: 100%;
    margin-bottom: 2rem !important;
    margin-right: 0 !important;
  }
  .courses-list .course__item .course__img {
    height: 250px;
  }
  .courses-list .course__item .course__name {
    margin: 2rem 0.5rem 2rem 0.4rem;
    height: auto;
  }
  .courses-list .course__item .course__name h3 {
    font-size: 2rem;
  }
  .courses-list .course__item .course__date {
    font-size: 2rem;
  }
  .courses-list .course__item .course__price {
    font-size: 2rem;
  }
  .courses-list .course__item .course-tags-list {
    margin-bottom: 2rem;
  }
  .courses-list .course__item .course-tags-list .course-tag__item {
    font-size: 2rem;
  }
}
.input__item.error {
  position: relative;
  border: 1px solid #EF4444 !important;
}
.input__item.error.content-check-container {
  border-radius: 8px;
}

.error .content-check-container .content-check {
  border: 1px solid #EF4444 !important;
}

.error-msg {
  color: red;
  font-size: 1rem;
  font-style: italic;
}

.input-column {
  display: flex;
}

.input-container {
  width: 100%;
  margin-bottom: 1.6rem;
  z-index: 1;
  position: relative;
}
.input-container.s {
  margin-bottom: 1rem;
}
.input-container.l {
  margin-bottom: 3rem;
}
.input-container a {
  font-size: 1.4rem;
  color: #000;
}
.input-container a:hover {
  text-decoration: none;
}
.input-container.input--group {
  display: flex;
  align-items: center;
}
.input-container.input--group.s .input__item {
  padding-right: 6rem;
  height: 35px;
  font-size: 1.1rem;
}
.input-container.input--group.s .btn {
  position: absolute;
  right: 10px;
  top: 9px;
  font-weight: 700;
  font-size: 1.2rem;
}
.input-container.input--group .input__item {
  padding-right: 8rem;
}
.input-container.input--group .btn {
  position: absolute;
  right: 15px;
  top: 15px;
  font-weight: 700;
}
.input-container.input--group i {
  color: #A0A0A0;
}
.input-container .input__label {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
  display: inline-block;
}
.input-container .input__item {
  padding: 0 1.6rem;
  border-radius: 5px;
  width: 100%;
  color: #000;
  border: 1px solid #E6E6E6;
  height: 50px;
  font-size: 1.4rem;
  font-family: "Montserrat", sans-serif;
}
.input-container .input__item.s {
  height: 35px;
  font-size: 1.2rem;
}
.input-container .input__item.dark {
  border: 1px solid #4D4D4D;
}
.input-container .input__item::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #AAAAAA;
  opacity: 1;
  /* Firefox */
}
.input-container .input__item:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #AAAAAA;
}
.input-container .input__item::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #AAAAAA;
}
.input-container .input__item.textarea {
  padding: 1.6rem;
  min-height: 70px;
  line-height: 1.4rem;
}
.input-container .input__item:focus {
  outline: none;
}
.input-container .custom-select {
  box-shadow: none;
  border-radius: 5px;
  height: 50px;
  position: relative;
  width: 100%;
  font-size: 1.4rem;
  background: #fff;
  border: 1px solid #E6E6E6;
  z-index: 0;
}
.input-container .custom-select.error {
  border: 1px solid #EF4444;
}
.input-container .custom-select:before {
  content: "";
  position: absolute;
  right: 1.6rem;
  top: 2rem;
  background: url("/img/chevron-down.svg");
  height: 10px;
  width: 17px;
  z-index: -1;
}
.input-container .custom-select select {
  color: #000;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 100%;
  padding: 0 1.6rem;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  z-index: 0;
  border: none;
}
.input-container .custom-select select:focus {
  outline: none;
  border: none;
}

.custom-check {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 30px;
  font-size: 1.4rem;
}
.custom-check a {
  text-decoration: underline;
}
.custom-check.s .checkmark {
  height: 17px;
  width: 17px;
}
.custom-check.m .checkmark {
  height: 22px;
  width: 22px;
}
.custom-check.m .checkmark:after {
  left: 7px;
  top: 3px;
}
.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-check input:checked ~ .checkmark {
  background-color: #1e3953;
  border: 1px solid #1e3953;
}
.custom-check input:checked ~ .checkmark:after {
  display: block;
}
.custom-check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fafafa;
  border-radius: 3px;
  border: 1px solid #ddd;
}
.custom-check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-check .checkmark:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg);
}
.content-check-container {
  display: flex;
  flex-wrap: wrap;
}
.content-check-container label {
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 0.9rem;
  margin-bottom: 0.9rem;
  flex-basis: calc(33.3% - 0.6rem);
}
.content-check-container label:nth-of-type(3n), .content-check-container label:last-of-type {
  margin-right: 0;
}
.content-check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.content-check-container input:checked ~ .content-check {
  background-color: #F0FFF4;
  border: 1px solid #38A169;
  color: #22543D;
}
.content-check-container input:checked ~ .content-check:before {
  content: "";
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  background: #9AE6B4;
  height: 20px;
  width: 20px;
}
.content-check-container input:checked ~ .content-check:after {
  content: "";
  right: 17px;
  top: 13px;
  width: 4px;
  height: 9px;
  border: solid #2F855A;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg);
  position: absolute;
}
.content-check-container input:hover ~ .content-check {
  background-color: #F0FFF4;
  border: 1px solid #F0FFF4;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
}
.content-check-container .content-check {
  position: relative;
  height: 70px;
  background: #fff;
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  padding: 0.7rem 1rem;
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content-check-container .content-check .check__name {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  opacity: 0.5;
}
.content-check-container .content-check .check__price {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.8rem;
}
.content-check-container .content-check .check__price .price--discount {
  font-size: 70%;
  color: #A0A0A0;
  font-weight: 600;
}

.form__name {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 1.4rem;
  text-align: left;
}

.form__subname {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 1.6rem;
  text-align: left;
  color: #A0A0A0;
}

.form__container {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.form__container .form__column {
  flex-basis: 100%;
  margin-right: 0;
  position: relative;
}
.form__container .form__column .form__name {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  text-align: left;
}
.form__container .form__column.featured:before {
  content: "";
  position: absolute;
  top: -2rem;
  left: -2rem;
  width: calc(100% + 4rem);
  height: calc(100% + 3rem);
  border-radius: 5px;
  border: 1px solid #000;
  background: transparent;
  z-index: 0;
}
.form__container .form__column.mr {
  margin-right: 4rem;
}
.form__container .form__column .btn {
  margin-top: 3rem;
  display: block;
}

@media (max-width: 768px) {
  .custom-check.s {
    width: 100%;
  }

  .form__name {
    font-size: 1.4rem;
  }
}
.euro {
  font-family: Arial;
}

.strike {
  position: relative;
}
.strike:before {
  content: "";
  height: 2px;
  width: 110%;
  background: red;
  position: absolute;
  left: -5%;
  top: 45%;
  transform: rotate(-10deg);
  box-shadow: 0 1px 0 0 white;
}
.strike.strike--s:before {
  height: 1px;
  box-shadow: none;
}

strong {
  font-weight: 700;
}

.light {
  font-weight: 300;
}

.text--xxl {
  font-size: 6rem;
}

.text--m {
  font-size: 1.6rem;
}

.text--s {
  font-size: 1.2rem;
}

.text--xs {
  font-size: 1rem;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right !important;
}

.text--danger {
  color: #EF4444 !important;
}

.text--danger-dark {
  color: #7F1D1D !important;
}

.text--warning {
  color: #FEC601 !important;
}

.text--success {
  color: #10B981;
}

.text--success-dark {
  color: #064E3B;
}

.text--light {
  color: #A0A0A0;
}

.text--featured {
  color: #1e3953;
}

.text--up {
  text-transform: uppercase;
}

.d--block {
  display: block;
}

.d--flex {
  display: flex;
}

.fd--c {
  flex-direction: column;
}

.fw--w {
  flex-wrap: wrap;
}

.jc--c {
  justify-content: center;
}

.jc--fe {
  justify-content: flex-end;
}

.jc--sb {
  justify-content: space-between;
}

.ai--fs {
  align-items: flex-start;
}

.ai--c {
  align-items: center;
}

.pt--0 {
  padding-top: 0 !important;
}

.pb--0 {
  padding-bottom: 0 !important;
}

.ml--0 {
  margin-left: 0 !important;
}

.ml--xs {
  margin-left: 1rem !important;
}

.ml--s {
  margin-left: 2rem !important;
}

.mt--0 {
  margin-top: 0 !important;
}

.mt--xs {
  margin-top: 1rem !important;
}

.mt--s {
  margin-top: 2rem !important;
}

.mt--m {
  margin-top: 4rem !important;
}

.mr--xxs {
  margin-right: 0.6rem !important;
}

.mr--xs {
  margin-right: 1rem !important;
}

.mr--s {
  margin-right: 2rem !important;
}

.mr--m {
  margin-right: 4rem !important;
}

.mb--0 {
  margin-bottom: 0 !important;
}

.mb--xxs {
  margin-bottom: 0.6rem !important;
}

.mb--xs {
  margin-bottom: 1rem !important;
}

.mb--s {
  margin-bottom: 2rem !important;
}

.mb--m {
  margin-bottom: 4rem !important;
}

.w--50 {
  width: 50%;
}

.w--60 {
  width: 60%;
}

.w--100 {
  width: 100%;
}

.text--link {
  text-decoration: none;
}
.text--link:hover {
  text-decoration: underline !important;
}

.img--bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.place-card {
  display: none;
}

@media (max-width: 768px) {
  .sm\:d--none {
    display: none;
  }

  .sm\:w--100 {
    width: 100%;
  }
}
.content-block {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E6E6E6;
  padding: 2rem 0;
}
.content-block:first-of-type {
  padding-top: 0;
}
.content-block:last-of-type {
  border-bottom: none;
}
.content-block .block__column .block__heading h4 {
  font-size: 1.8rem;
  font-weight: 700;
}
.content-block .block__column .block__details {
  display: flex;
  margin-top: 1rem;
}
.content-block .block__column .block__details .detail__item {
  font-size: 1.4rem;
}
.content-block .block__column .block__details .detail__item img {
  width: 14px;
  height: 14px;
}

.header {
  min-height: 80px;
  max-height: 1080px;
  padding: 0 !important;
  background: #fff;
  overflow: unset;
  position: relative;
}
.header.o--h {
  overflow: hidden;
}
.header .header__nav {
  background: rgba(255, 255, 255, 0.98);
  border-bottom: 1px solid #E6E6E6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
.header .header__nav.nav--bg .btn--text {
  color: #000;
}
.header .header__nav .header__nav__inner {
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding: 0;
}
.header .header__nav .header__nav__inner .nav__column .nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.header .header__nav .header__nav__inner .nav__column .nav__list .nav__item a {
  font-weight: 700;
  font-size: 1.6rem;
  padding: 0 2rem;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
}
.header .header__nav .header__nav__inner .nav__column .nav__list .nav__item a:hover {
  text-decoration: none;
}
.header .header__nav .header__nav__inner .nav__column.logo {
  padding: 2rem 0;
}
.header .header__nav .header__nav__inner .nav__column.logo img {
  height: 100%;
}
.header .header__nav .header__nav__inner .nav__column.back {
  display: flex;
  align-items: center;
}
.header .header__nav .header__nav__inner .nav__column.back .nav__list {
  margin-left: 0;
}
.header .header__nav .header__nav__inner .nav__column.back .nav__list a {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
}
.header .header__nav .header__nav__inner .nav__column.back .nav__list a img {
  margin-right: 2rem;
  width: 20px;
}
.header .header__nav .header__nav__inner .nav__column.navbar {
  display: flex;
  align-items: center;
  margin-right: 11rem;
}
.header .header__nav .header__nav__inner .nav__column.navbar .nav__list {
  height: 100%;
}
.header .header__nav .header__nav__inner .nav__column.navbar .nav__list .nav__item a {
  height: 100%;
  display: flex;
  align-items: center;
}
.header .header__nav .header__nav__inner .nav__column.navbar .nav__list .nav__item.active {
  box-shadow: inset 0 -4px 0 #FEC601;
}
.header .header__nav .header__nav__inner .nav__column.navbar .nav__list .nav__item:hover {
  box-shadow: inset 0 -4px 0 #FEC601;
}
.header .header__nav .header__nav__inner .nav__column.account {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.header .header__nav .header__nav__inner .nav__column.account .btn--nav {
  font-size: 1.3rem;
  padding: 1rem 2rem;
}
.header .header__nav .header__nav__inner .nav__column.account .nav__account-btn {
  font-size: 1.2rem;
  color: #000;
  margin-right: 2rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  height: 100%;
  position: relative;
}
.header .header__nav .header__nav__inner .nav__column.account .nav__account-btn:after {
  content: "";
  width: 1px;
  height: 16px;
  position: absolute;
  left: 100%;
  top: calc(50% - 8px);
  background: #000;
}
.header .header__nav .header__nav__inner .nav__column.account .nav__account-btn.active {
  box-shadow: inset 0 -4px 0 #FEC601;
}
.header .header__nav .header__nav__inner .nav__column.account .nav__account-btn svg {
  fill: #000;
  height: 16px;
}
.header .header__nav .header__nav__inner .nav__column.account .nav__logout-btn {
  height: 16px;
}
.header .header__nav .header__nav__inner .nav__column.social {
  display: flex;
  align-items: center;
}
.header .header__nav .header__nav__inner .nav__column.social .social-icons {
  display: flex;
}
.header .header__nav .header__nav__inner .nav__column.social .social-icons .social__icon {
  margin-right: 1rem;
}
.header .header__nav .header__nav__inner .nav__column.social .social-icons .social__icon img {
  width: 30px;
}
.header .header__nav .header__nav__inner .nav__column.social .social-icons .social__icon:last-of-type {
  margin-right: 0;
}
.header .header__content {
  position: relative;
  z-index: 1;
  width: 55%;
  height: 100vh;
  max-height: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header .header__content.s {
  height: auto;
  min-height: 650px;
  padding-top: 80px;
}
.header .header__content .header-read__more {
  position: absolute;
  left: 0;
  bottom: 60px;
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
}
.header .header__content .header-read__more:after {
  content: "";
  width: 1px;
  height: 60px;
  background: #E6E6E6;
  position: absolute;
  left: 17px;
  top: 34px;
  z-index: 0;
}
.header .header__content .header-read__more img {
  margin-right: 1rem;
}
.header .header__content .header-read__more p {
  font-size: 1.4rem;
}
.header .header__content .header-read__more p:hover {
  text-decoration: underline;
}
.header .header__content .header-tags-list {
  margin-bottom: 2rem;
}
.header .header__content .header-tags-list .header-tags__item {
  font-size: 1.6rem;
  text-transform: uppercase;
}
.header .header__content h1 {
  position: relative;
  margin-bottom: 7rem;
  width: 100%;
  font-size: 4.4rem;
  text-transform: uppercase;
}
.header .header__content h1.s {
  font-size: 3.4rem;
}
.header .header__content h1.no--dot:after {
  display: none;
}
.header .header__content h1:after {
  content: "";
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background: #FEC601;
  position: absolute;
  left: 0;
  bottom: -5rem;
}
.header .header__content .header__date {
  font-size: 2.8rem;
  margin-bottom: 1rem;
  font-weight: 700;
}
.header .header__content .header__place {
  font-size: 1.8rem;
  line-height: 2.6rem;
  margin-bottom: 6rem;
}
.header .header__content a {
  align-self: flex-start;
}
.header .header-img--bg {
  position: absolute;
  left: calc(50% + 200px);
  bottom: 30px;
  width: 1000px;
  height: 1000px;
  z-index: 0;
}
.header .header-img--bg img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.header .header-img--bg:after {
  content: "";
  position: absolute;
  top: 120px;
  left: -200px;
  background: #F4F4F4;
  width: 550px;
  height: 550px;
  border-radius: 50%;
  z-index: -1;
}
.header .header-s-img--bg {
  position: absolute;
  right: 0;
  top: 150px;
  width: 450px;
  height: 450px;
  z-index: 0;
}
.header .header-s-img--bg img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.header .header-s-img--bg:after {
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  background: #FEC601;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.header .header-s-img--bg:before {
  content: "";
  position: absolute;
  bottom: -50px;
  right: 100px;
  background: #1e3953;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 1;
}

@media (max-width: 768px) {
  .header {
    margin-bottom: 0;
    min-height: 60px;
    max-height: unset;
    overflow: hidden;
  }
  .header .header__nav {
    background: #fff;
    border-bottom: 1px solid #E6E6E6;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
  }
  .header .header__nav .header__nav__inner {
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 1rem 0;
  }
  .header .header__nav .header__nav__inner .nav__group {
    justify-content: space-between;
  }
  .header .header__nav .header__nav__inner .nav__column {
    /*&.social {

        display: flex;
        align-items: center;

        .social-icons {
            display: flex;

            .social__icon {
                margin-right: 1rem;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }*/
  }
  .header .header__nav .header__nav__inner .nav__column .nav__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  .header .header__nav .header__nav__inner .nav__column .nav__list .nav__item a {
    font-weight: 700;
    font-size: 1.2rem;
    padding: 0 0.5rem;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
  }
  .header .header__nav .header__nav__inner .nav__column .nav__list .nav__item a:hover {
    text-decoration: none;
  }
  .header .header__nav .header__nav__inner .nav__column.logo {
    padding: 0.5rem 0;
    width: 150px;
  }
  .header .header__nav .header__nav__inner .nav__column.logo img {
    height: 100%;
  }
  .header .header__nav .header__nav__inner .nav__column.back {
    display: flex;
    align-items: center;
  }
  .header .header__nav .header__nav__inner .nav__column.back .nav__list {
    margin-left: 1rem;
  }
  .header .header__nav .header__nav__inner .nav__column.back .nav__list a {
    display: flex;
    align-items: center;
  }
  .header .header__nav .header__nav__inner .nav__column.back .nav__list a p {
    opacity: 0.3;
  }
  .header .header__nav .header__nav__inner .nav__column.back .nav__list a img {
    margin-right: 2rem;
    width: 20px;
  }
  .header .header__nav .header__nav__inner .nav__column.navbar {
    display: flex;
    align-items: center;
    margin-right: 0;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: #F4F4F4;
    border-bottom: 1px solid #E6E6E6;
  }
  .header .header__nav .header__nav__inner .nav__column.navbar .nav__list {
    width: 100%;
    justify-content: space-between;
    padding: 0 1rem;
  }
  .header .header__nav .header__nav__inner .nav__column.navbar .nav__list li {
    padding: 1rem 0;
  }
  .header .header__content {
    width: 100%;
    height: 80vh;
    min-height: unset !important;
    margin-top: 7rem;
  }
  .header .header__content .header-read__more {
    display: none;
  }
  .header .header__content .header-tags-list {
    margin-bottom: 2rem;
  }
  .header .header__content .header-tags-list .header-tags__item {
    font-size: 1.6rem;
  }
  .header .header__content h1 {
    margin-bottom: 4rem;
    font-size: 3.2rem;
  }
  .header .header__content h1:after {
    height: 24px;
    width: 24px;
    bottom: -3rem;
  }
  .header .header__content .header__date {
    font-size: 2.6rem;
  }
  .header .header__content .header__place {
    font-size: 1.6rem;
    margin-bottom: 3rem;
  }
  .header .header-img--bg {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    width: 200px;
  }
  .header .header-s-img--bg {
    position: absolute;
    right: -20px;
    top: 50px;
    width: 150px;
    height: 150px;
  }
  .header .header-s-img--bg:after {
    content: "";
    position: absolute;
    top: 10px;
    right: 10px;
    background: #FEC601;
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .header .header-s-img--bg:before {
    content: "";
    position: absolute;
    bottom: -5px;
    right: 100px;
    background: #1e3953;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    z-index: 1;
  }
}
.quick-login-container {
  display: none;
  padding: 2rem;
  background: #fff;
  border: 1px solid #E6E6E6;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: absolute;
  top: calc(100% - 5px);
  right: 0;
  width: 320px;
}
.quick-login-container.active {
  display: block;
}
.quick-login-container .quick-login__register {
  text-align: center;
}
.quick-login-container .quick-login__register span {
  font-size: 1.2rem;
}
.quick-login-container .quick-login__register span a {
  color: #FEC601;
  font-weight: 600;
}

.register-steps {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-left: 20%;
}
.register-steps > i {
  color: #FEC601;
  font-size: 1.8rem;
  padding-top: 50px;
}
.register-steps .step {
  width: 130px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.register-steps .step .step__circle {
  background: #F4F4F4;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.register-steps .step .step__circle i {
  font-size: 5rem;
  color: #4D4D4D;
}
.register-steps .step .step__desc {
  margin-top: 2rem;
  font-size: 1.4rem;
}

@media (max-width: 768px) {
  .register-steps {
    width: 100%;
    margin-left: 0;
  }
  .register-steps > i {
    font-size: 1.2rem;
    padding-top: 35px;
  }
  .register-steps .step {
    width: 130px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .register-steps .step .step__circle {
    background: #F4F4F4;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .register-steps .step .step__circle i {
    font-size: 3rem;
    color: #4D4D4D;
  }
  .register-steps .step .step__desc {
    margin-top: 1.2rem;
    font-size: 1.2rem;
  }
}
.label {
  font-weight: 600;
  display: inline-block;
}
.label.label--circle {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.label.label--circle.m {
  width: 25px;
  height: 25px;
}
.label.shadow {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}
.label.label--s {
  padding: 0.2rem 0.5rem !important;
  font-size: 0.8rem !important;
  border-radius: 4px;
}
.label.label--m {
  padding: 0.4rem 1rem !important;
  font-size: 1rem !important;
  border-radius: 6px;
}
.label.label--l {
  padding: 0.3rem 1.5rem !important;
  font-size: 1.5rem !important;
  font-weight: 800 !important;
  border-radius: 10px;
}
.label.label--success {
  background: #10B981;
  color: #fff;
  border: 1px solid #10B981;
}
.label.label--danger {
  background: #EF4444;
  color: #fff;
  border: 1px solid #EF4444;
}
.label.label--danger-inv {
  background: #FECACA;
  color: #7F1D1D;
  border: 1px solid #FECACA;
}
.label.label--default {
  background: transparent;
  color: #E6E6E6;
  border: 1px solid #A0A0A0;
}
.label.label--white {
  background: #fff;
  color: #A0A0A0;
}

.hp-header {
  min-height: 80px;
  max-height: 960px;
  padding: 0 !important;
  position: relative;
  background: url("/img/homepage/header_bg.jpg") bottom right;
  background-size: cover;
  z-index: 9999;
}
.hp-header:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2));
}
.hp-header .img--bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.hp-header .header__nav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
.hp-header .header__nav.nav--bg {
  background: rgba(255, 255, 255, 0.98);
  border-bottom: 1px solid #E6E6E6;
  transition: all 0.1s;
}
.hp-header .header__nav.nav--bg .nav__item a {
  color: #000 !important;
}
.hp-header .header__nav.nav--bg .btn--text {
  color: #000 !important;
}
.hp-header .header__nav.nav--bg .logo__dark {
  display: block;
}
.hp-header .header__nav.nav--bg .logo__white {
  display: none;
}
.hp-header .header__nav.nav--bg .social-icons.white {
  display: none !important;
}
.hp-header .header__nav.nav--bg .social-icons.dark {
  display: flex !important;
}
.hp-header .header__nav.nav--bg .account .nav__account-btn svg, .hp-header .header__nav.nav--bg .account .nav__logout-btn svg {
  fill: #000 !important;
}
.hp-header .header__nav.nav--bg .account .nav__account-btn {
  border-right-color: #000 !important;
  color: #000 !important;
}
.hp-header .header__nav .btn--text {
  color: #fff;
}
.hp-header .header__nav .logo__dark {
  display: none;
}
.hp-header .header__nav .logo__white {
  display: block;
}
.hp-header .header__nav .social-icons.white {
  display: flex !important;
}
.hp-header .header__nav .social-icons.dark {
  display: none !important;
}
.hp-header .header__nav .header__nav__inner {
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding: 0;
}
.hp-header .header__nav .header__nav__inner .nav__column {
  /*&.social {

      display: flex;
      align-items: center;

      .social-icons {
          display: flex;

          .social__icon {
              margin-right: 1rem;

              img {
                  width: 30px;
              }

              &:last-of-type {
                  margin-right: 0;
              }
          }
      }
  }*/
}
.hp-header .header__nav .header__nav__inner .nav__column .nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.hp-header .header__nav .header__nav__inner .nav__column .nav__list .nav__item {
  display: flex;
}
.hp-header .header__nav .header__nav__inner .nav__column .nav__list .nav__item a {
  height: 100%;
  font-weight: 700;
  font-size: 1.6rem;
  padding: 0 2rem;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.hp-header .header__nav .header__nav__inner .nav__column .nav__list .nav__item a:hover {
  text-decoration: none;
}
.hp-header .header__nav .header__nav__inner .nav__column.logo {
  padding: 2rem 0;
}
.hp-header .header__nav .header__nav__inner .nav__column.logo img {
  height: 100%;
}
.hp-header .header__nav .header__nav__inner .nav__column.navbar {
  display: flex;
  align-items: center;
  margin-right: 0;
}
.hp-header .header__nav .header__nav__inner .nav__column.navbar .nav__list {
  height: 100%;
}
.hp-header .header__nav .header__nav__inner .nav__column.navbar .nav__list .nav__item:hover {
  box-shadow: inset 0 -4px 0 #FEC601;
}
.hp-header .header__nav .header__nav__inner .nav__column.account {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.hp-header .header__nav .header__nav__inner .nav__column.account .btn--nav {
  font-size: 1.3rem;
  padding: 1rem 2rem;
}
.hp-header .header__nav .header__nav__inner .nav__column.account .nav__account-btn {
  font-size: 1.2rem;
  color: #fff;
  margin-right: 2rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  height: 100%;
  position: relative;
}
.hp-header .header__nav .header__nav__inner .nav__column.account .nav__account-btn:after {
  content: "";
  width: 1px;
  height: 16px;
  position: absolute;
  left: 100%;
  top: calc(50% - 8px);
  background: #fff;
}
.hp-header .header__nav .header__nav__inner .nav__column.account .nav__account-btn svg {
  fill: #fff;
  height: 16px;
  margin-right: 1rem;
}
.hp-header .header__nav .header__nav__inner .nav__column.account .nav__logout-btn {
  display: flex;
}
.hp-header .header__nav .header__nav__inner .nav__column.account .nav__logout-btn svg {
  fill: #fff;
  height: 16px;
}
.hp-header .header__content {
  width: 80%;
  height: 100vh;
  max-height: 980px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  padding-top: 81px;
}
.hp-header .header__content h1 {
  position: relative;
  margin-bottom: 7rem;
  width: 100%;
  font-size: 4.8rem;
}
.hp-header .header__content h1:after {
  content: "";
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background: #FEC601;
  position: absolute;
  left: 0;
  bottom: -5rem;
}
.hp-header .header__content p {
  font-size: 1.8rem;
  margin-bottom: 6rem;
  width: 70%;
}
.hp-header .header__content a {
  align-self: flex-start;
}

@media (max-width: 768px) {
  .hp-header {
    margin-bottom: 0;
  }
  .hp-header .header__nav.nav--bg .navbar {
    background: #F4F4F4 !important;
    border-bottom: 1px solid #E6E6E6;
  }
  .hp-header .header__nav .header__nav__inner {
    height: 60px;
    padding: 1rem 0;
  }
  .hp-header .header__nav .header__nav__inner .nav__column .nav__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  .hp-header .header__nav .header__nav__inner .nav__column .nav__list .nav__item a {
    font-weight: 700;
    font-size: 1.4rem;
    padding: 0 1rem;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
  }
  .hp-header .header__nav .header__nav__inner .nav__column .nav__list .nav__item a:hover {
    text-decoration: none;
  }
  .hp-header .header__nav .header__nav__inner .nav__column.logo {
    padding: 0.5rem 0;
    width: 150px;
  }
  .hp-header .header__nav .header__nav__inner .nav__column.logo img {
    height: 100%;
  }
  .hp-header .header__nav .header__nav__inner .nav__column.navbar {
    display: flex;
    align-items: center;
    margin-right: 0;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.1s;
  }
  .hp-header .header__nav .header__nav__inner .nav__column.navbar .nav__list {
    width: 100%;
    justify-content: space-between;
    padding: 0 1rem;
  }
  .hp-header .header__nav .header__nav__inner .nav__column.navbar .nav__list li {
    padding: 1rem 0;
  }
  .hp-header .header__nav .header__nav__inner .nav__column.social {
    display: flex;
    align-items: center;
  }
  .hp-header .header__nav .header__nav__inner .nav__column.social .social-icons {
    display: flex;
  }
  .hp-header .header__nav .header__nav__inner .nav__column.social .social-icons .social__icon {
    margin-right: 1rem;
  }
  .hp-header .header__nav .header__nav__inner .nav__column.social .social-icons .social__icon:last-of-type {
    margin-right: 0;
  }
  .hp-header .header__content {
    width: 100%;
    height: 85vh;
  }
  .hp-header .header__content h1 {
    margin-bottom: 5rem;
    width: 100%;
    font-size: 3rem;
  }
  .hp-header .header__content h1:after {
    height: 24px;
    width: 24px;
    left: 0;
    bottom: -4rem;
  }
  .hp-header .header__content p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 3rem;
  }
}
.reviews h2 {
  font-size: 3rem;
  margin-bottom: 5rem;
}

.reviews-list .reviews__item {
  text-align: center;
  padding-top: 2rem;
}
.reviews-list .reviews__item .reviews__text {
  font-size: 1.6rem;
  margin-bottom: 2rem;
  position: relative;
  display: inline-block;
}
.reviews-list .reviews__item .reviews__text:after {
  content: "“";
  color: #1e3953;
  font-size: 6rem;
  font-weight: 700;
  position: absolute;
  left: -40px;
  top: -25px;
}
.reviews-list .reviews__item .reviews__text:before {
  content: "“";
  color: #1e3953;
  font-size: 6rem;
  font-weight: 700;
  position: absolute;
  right: -40px;
  bottom: -25px;
  transform: rotate(180deg);
}
.reviews-list .reviews__item .reviews__author {
  font-style: italic;
  font-weight: 700;
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .reviews-list .reviews__item .reviews__text {
    font-size: 2rem;
  }
  .reviews-list .reviews__item .reviews__text:after {
    left: 0;
    top: -35px;
  }
  .reviews-list .reviews__item .reviews__text:before {
    right: 0;
    bottom: -35px;
  }
  .reviews-list .reviews__item .reviews__author {
    font-size: 1.4rem;
  }
}
.hp-featured {
  padding-bottom: 16rem;
}
.hp-featured .featured__container {
  display: flex;
  flex-wrap: wrap;
}
.hp-featured .featured__container .featured__icons {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding-right: 100px;
}
.hp-featured .featured__container .featured__icons .icons__item {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
}
.hp-featured .featured__container .featured__icons .icons__item:last-of-type {
  margin-bottom: 0;
}
.hp-featured .featured__container .featured__icons .icons__item .icons__icon {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background: #FEC601;
  display: flex;
  flex-shrink: 0;
}
.hp-featured .featured__container .featured__icons .icons__item .icons__icon img {
  width: 50%;
  margin: auto;
}
.hp-featured .featured__container .featured__icons .icons__item .icons__content {
  font-size: 1.6rem;
  font-weight: 700;
  margin-left: 3rem;
}
.hp-featured .featured__content {
  width: calc(60% - 100px);
  background: #fff;
  border-radius: 5px;
  padding: 4rem;
  color: #000;
  position: relative;
}
.hp-featured .featured__content h2 {
  font-size: 3.6rem;
  margin-bottom: 3rem;
  text-align: left;
}
.hp-featured .featured__content p {
  font-size: 1.6rem;
  padding-right: 50px;
}
.hp-featured .featured__content .featured__content__icon {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: #FEC601;
  display: flex;
  position: absolute;
  right: -100px;
  bottom: -50px;
}
.hp-featured .featured__content .featured__content__icon img {
  width: 50%;
  margin: auto;
}

@media (max-width: 768px) {
  .hp-featured {
    padding-bottom: 10rem;
  }
  .hp-featured .featured__container .featured__icons {
    width: 100%;
    padding-right: 0;
  }
  .hp-featured .featured__container .featured__icons .icons__item {
    margin-bottom: 4rem;
  }
  .hp-featured .featured__container .featured__icons .icons__item:last-of-type {
    margin-bottom: 6rem;
  }
  .hp-featured .featured__container .featured__icons .icons__item .icons__icon {
    flex-shrink: 0;
  }
  .hp-featured .featured__content {
    width: 100%;
    padding: 3rem 3rem 8rem 3rem;
  }
  .hp-featured .featured__content h2 {
    font-size: 2.6rem;
    margin-bottom: 2rem;
  }
  .hp-featured .featured__content p {
    font-size: 2rem;
    padding-right: 0;
  }
  .hp-featured .featured__content .featured__content__icon {
    width: 120px;
    height: 120px;
    position: absolute;
    left: calc(50% - 60px);
    bottom: -60px;
  }
  .hp-featured .featured__content .featured__content__icon img {
    width: 50%;
    height: auto;
    margin: auto;
  }
}
.article .article__column {
  display: flex;
}
.article .article__column .article__left {
  width: 100%;
}
.article .article__column .article__right {
  width: 35%;
}
.article .article-tags {
  display: flex;
  margin-bottom: 4rem;
}
.article .article-tags .tag__item {
  margin-right: 2rem;
  position: relative;
  font-size: 1.4rem;
  padding-left: 2rem;
}
.article .article-tags .tag__item:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #FEC601;
  position: absolute;
  left: 0;
  top: 5px;
}
.article .article-tags .tag__item a {
  color: #000;
}
.article .article-tags .tag__item:last-of-type {
  margin-right: 0;
}
.article h1 {
  text-transform: uppercase;
  font-size: 3.2rem;
  margin-bottom: 2rem;
}
.article h2 {
  text-transform: uppercase;
  margin-bottom: 3rem;
}
.article .article__details {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 4rem;
}
.article .article__details .detail__item {
  font-size: 1.1rem;
  display: flex;
}
.article .article__details .detail__item i {
  font-size: 1.6em;
  color: #A0A0A0;
}
.article .article__block {
  margin-bottom: 3rem;
}
.article .article__block h2, .article .article__block h3, .article .article__block h4 {
  text-align: left;
}
.article .article__block:last-of-type {
  margin-bottom: 0;
}
.article .article__block.img img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
}
.article .article__block.video .video__inner {
  background: #F4F4F4;
  padding: 3rem;
  border-radius: 10px;
  overflow: hidden;
}
.article .article__block.text a {
  font-weight: bold;
  color: #1e3953;
}
.article .article__block.text p, .article .article__block.text li {
  font-size: 1.8rem;
  line-height: 3rem;
  font-weight: 400;
}
.article .article__block.text p:last-of-type, .article .article__block.text li:last-of-type {
  margin-bottom: 0;
}
.article .article__block.text h2 {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}
.article .article__block.text h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.article .article__block.text p {
  margin-top: 1.6rem;
}
.article .article__block.text p:first-of-type {
  margin-top: 0;
}
.article .article__block.text ul {
  padding-left: 32px;
  list-style: none;
  margin-top: 1.6rem;
}
.article .article__block.text ul li {
  position: relative;
  margin-bottom: 0.6rem;
}
.article .article__block.text ul li:before {
  content: "";
  position: absolute;
  left: -26px;
  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #FEC601;
}
.article .article__block.text ol {
  padding-left: 32px;
  margin-top: 1.6rem;
  list-style: none;
}
.article .article__block.text ol li {
  position: relative;
  margin-bottom: 0.6rem;
  counter-increment: li;
}
.article .article__block.text ol li:before {
  content: counter(li);
  color: #FEC601;
  font-weight: 700;
  position: absolute;
  right: calc(100% + 14px);
  top: 0;
  font-size: 16px;
}
.article .article__block.text ol li:after {
  content: "/.";
  color: #FEC601;
  font-weight: 700;
  position: absolute;
  right: calc(100% + 5px);
  top: 0;
  font-size: 13px;
}
.article .article__block.text blockquote {
  margin: 4rem 0 4rem 0;
  background: #F4F4F4;
  border-radius: 0 5px 5px 0;
  border-left: 5px solid #1e3953;
  padding: 2rem 2rem 2rem 8rem;
  font-size: 1.8rem;
  line-height: 3rem;
  font-weight: 400;
  font-style: italic;
  position: relative;
}
.article .article__block.text blockquote:before {
  content: "§";
  color: #1e3953;
  position: absolute;
  left: 30px;
  top: 25px;
  font-size: 4rem;
  font-weight: 600;
}
.article .article__block.links h3 {
  margin-bottom: 1rem;
}
.article .article__block.links ul {
  padding-left: 32px;
  list-style: none;
  margin-top: 1.6rem;
}
.article .article__block.links ul li {
  position: relative;
  margin-bottom: 0.4rem;
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-style: italic;
}
.article .article__block.links ul li:before {
  content: "";
  position: absolute;
  left: -26px;
  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1e3953;
}
.article .article__block.links ul li a {
  font-weight: 600;
  color: #1e3953;
}
.article .article__block.heading h3 {
  margin-bottom: 2rem;
}
.article .article__share {
  display: flex;
  margin-bottom: 4rem;
}
.article .article__share .share__btn {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  background: #E6E6E6;
  color: #4D4D4D;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.8rem 4rem;
  border-radius: 5px;
}
.article .article__share .share__btn:last-of-type {
  margin-right: 0;
}
.article .article__authors {
  padding-top: 3rem;
  margin-top: 3rem;
  border-top: 1px solid #F4F4F4;
}
.article .article__author {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.article .article__author:last-of-type, .article .article__author:only-of-type {
  margin-bottom: 0;
}
.article .article__author .author__img {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  position: relative;
  margin-right: 3rem;
}
.article .article__author .author__img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.article .article__author .author__details h3 {
  font-size: 1.7rem;
  text-align: left;
  margin-bottom: 0;
}
.article .article__author .author__details h4 {
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 0;
}
.article .article__author .author__details p {
  margin-top: 1.8rem;
  font-size: 1.4rem;
}

.category-container {
  display: flex;
  flex-wrap: wrap;
}
.category-container .category__item {
  background: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  flex-basis: calc(33.3% - 1.4rem);
  margin-right: 2.1rem;
  margin-bottom: 3rem;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s;
  text-decoration: none;
  position: relative;
  display: flex;
  flex-direction: column;
}
.category-container .category__item p, .category-container .category__item h3, .category-container .category__item h4, .category-container .category__item h5 {
  color: #000;
  margin: 0;
  text-align: left;
}
.category-container .category__item:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.25);
}
.category-container .category__item:nth-of-type(3n) {
  margin-right: 0;
}
.category-container .category__item .category__img {
  height: 200px;
  overflow: hidden;
  position: relative;
}
.category-container .category__item .category__img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.category-container .category__item .category__img .category__lock {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
}
.category-container .category__item .category__img .category__lock .icon__lock {
  background: #EF4444;
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.5);
}
.category-container .category__item .category__img .category__lock i {
  color: #fff;
  font-size: 2rem;
}
.category-container .category__item .category__name {
  padding: 2rem 2.5rem 2rem 2.5rem;
  border-bottom: 1px solid #F4F4F4;
}
.category-container .category__item .category__name h3 {
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}
.category-container .category__item .category__details {
  padding: 2rem 2.5rem 0 2.5rem;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  color: #000;
}
.category-container .category__item .category__details .detail__item {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  width: 50%;
}
.category-container .category__item .category__details .detail__item i {
  font-size: 1.6rem;
  color: #1e3953;
  margin-right: 1rem;
}
.category-container .category__item .category__desc {
  padding: 2rem 2.5rem 2rem 2.5rem;
  flex-grow: 1;
}
.category-container .category__item .category__desc p {
  font-size: 1.4rem;
}
.category-container .category__item .category__more {
  background: #F4F4F4;
  color: #1e3953;
  padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  font-size: 1.4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.category-container .category__item .category__more i {
  margin-left: 1rem;
  font-size: 2rem;
  color: #FEC601;
}

.course__item .course__location {
  display: flex;
  padding: 2rem 2.5rem 2rem 2.5rem;
}
.course__item .course__location .course__top-left {
  margin-right: 3rem;
}
.course__item .course__location .course__top-left, .course__item .course__location .course__top-right {
  display: flex;
  align-items: center;
}
.course__item .course__location .course__top-left i, .course__item .course__location .course__top-right i {
  font-size: 1.6rem;
  color: #1e3953;
  margin-right: 1rem;
}
.course__item .course__location .course__date, .course__item .course__location .course__city {
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
}
.course__item .course-prices {
  display: flex;
  justify-content: space-around;
}
.course__item .course__price {
  padding: 2rem 0rem 2rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.course__item .course__price .price__type {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #A0A0A0;
  margin-bottom: 1rem;
}
.course__item .course__price h4 {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  margin-bottom: 0.4rem;
  position: relative;
}
.course__item .course__price h4 .euro {
  padding-left: 0.8rem;
}
.course__item .course__price h4 .price__discount-value {
  padding: 0.2rem;
  background: #E50056;
  color: #fff;
  position: absolute;
  top: -8px;
  right: -28px;
  font-weight: 600;
  border-radius: 3px;
  font-size: 1rem;
}
.course__item .course__price h5 {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 400;
}
.course__item .course-tags-list {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.course__item .course-tags-list .course-tag__item {
  font-size: 1.6rem;
  position: relative;
  margin-right: 25px;
}
.course__item .course-tags-list .course-tag__item:last-of-type:after {
  display: none;
}
.course__item .course-tags-list .course-tag__item:after {
  content: "&";
  position: absolute;
  right: -20px;
  top: 0;
}

@media (max-width: 768px) {
  .category-container .category__item {
    padding: 0;
    flex-basis: 100%;
    margin-bottom: 2rem !important;
    margin-right: 0 !important;
  }
  .category-container .category__item .course__img {
    height: 250px;
  }
  .category-container .category__item .course__name {
    margin: 2rem 0.5rem 2rem 0.4rem;
    height: auto;
  }
  .category-container .category__item .course__name h3 {
    font-size: 2rem;
  }
  .category-container .category__item .course__date {
    font-size: 2rem;
  }
  .category-container .category__item .course__price {
    font-size: 2rem;
  }
  .category-container .category__item .course-tags-list {
    margin-bottom: 2rem;
  }
  .category-container .category__item .course-tags-list .course-tag__item {
    font-size: 2rem;
  }
}
.error-page {
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.error-page h1 {
  font-size: 6rem;
}
.error-page h2 {
  font-size: 3rem;
  margin-bottom: 3rem;
}
.error-page p {
  margin-bottom: 3rem;
  line-height: 2rem;
  font-size: 1.4rem;
  font-weight: 400;
}
.error-page a {
  color: inherit;
  text-decoration: none;
}
.error-page a:hover {
  text-decoration: underline;
}
.error-page img {
  width: 150px;
}
.error-page i {
  font-size: 30rem;
  color: #333;
}

.loader-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #1d364f;
  display: flex;
  border-radius: 5px;
}

.loader {
  margin: auto;
  border: 3px solid rgba(255, 255, 255, 0.1);
  /* Light grey */
  border-top: 3px solid #FEC601;
  /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.guests-list {
  display: flex;
  flex-wrap: wrap;
}
.guests-list .guest__item {
  flex-shrink: 0;
  flex-basis: calc(50% - 2.5rem);
  margin-right: 3rem;
  margin-bottom: 8rem;
  display: flex;
  align-items: center;
}
.guests-list .guest__item.full {
  flex-basis: 100%;
}
.guests-list .guest__item:nth-of-type(2n) {
  margin-right: 0;
}
.guests-list .guest__item:last-of-type {
  margin-bottom: 0;
}
.guests-list .guest__item:nth-last-child(2) {
  margin-bottom: 0;
}
.guests-list .guest__item .guest__img {
  flex-shrink: 0;
  width: 180px;
  height: 180px;
  position: relative;
  margin-right: 3rem;
}
.guests-list .guest__item .guest__img .guest__position {
  position: absolute;
  left: 10px;
  bottom: 20px;
  width: calc(100% - 20px);
  padding: 8px 4px;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
  z-index: 1;
}
.guests-list .guest__item .guest__img .guest__position.primary {
  background: #1e3953;
}
.guests-list .guest__item .guest__img .guest__position.secondary {
  background: #FEC601;
}
.guests-list .guest__item .guest__img .guest__position.tertiary {
  background: #E50056;
}
.guests-list .guest__item .guest__img .guest__position.secret {
  background: #000;
}
.guests-list .guest__item .guest__img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.guests-list .guest__item .guest__details h3 {
  text-align: inherit;
  font-size: 1.7rem;
  margin-bottom: 0;
}
.guests-list .guest__item .guest__details h4 {
  font-size: 1.6rem;
  font-weight: 600;
}
.guests-list .guest__item .guest__details p {
  margin-top: 3rem;
  font-size: 1.4rem;
}

@media (max-width: 768px) {
  .guests-list .guest__item {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .guests-list .guest__item .guest__img {
    margin-bottom: 3rem;
    margin-right: 0;
  }
  .guests-list .guest__item .guest__details {
    text-align: center !important;
  }
  .guests-list .guest__item .guest__details h3, .guests-list .guest__item .guest__details h4 {
    font-size: 1.4rem;
  }
  .guests-list .guest__item .guest__details h4 {
    margin-bottom: 2rem;
  }
  .guests-list .guest__item .guest__details p {
    font-size: 1.4rem;
    width: 100%;
  }
}
.logos-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.logos-list .logos__item {
  flex-shrink: 0;
  flex-basis: 20%;
  padding: 3rem 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logos-list .logos__item img {
  max-width: 100%;
  max-height: 70px;
}

@media (max-width: 768px) {
  .sponsors .wrapper {
    width: 100%;
    margin-left: 0;
  }

  .logos-list .logos__item {
    flex-shrink: 0;
    flex-basis: 50%;
    padding: 3rem 2rem;
    margin-bottom: 1rem;
    margin-right: 0;
  }
}
.pricelist {
  padding-bottom: 8rem;
}

.pricelist-list {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.pricelist-list .pricelist__item {
  max-width: 450px;
  flex: 1 1 100%;
  max-width: 450px;
  margin-right: 1.2rem;
  padding: 3rem;
  position: relative;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background: white;
  text-align: center;
  color: #000;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.pricelist-list .pricelist__item:last-of-type {
  margin-right: 0;
}
.pricelist-list .pricelist__item.t {
  padding-top: 7.5rem;
}
.pricelist-list .pricelist__item.b {
  padding-bottom: 7.5rem;
}
.pricelist-list .pricelist__item .label-top {
  padding: 1rem 0 1rem 0;
  background: #E50056;
  color: #fff;
  font-weight: 700;
  font-size: 1.6rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.pricelist-list .pricelist__item .label-bottom {
  padding: 1rem 0;
  background: #FEC601;
  color: #fff;
  font-weight: 700;
  font-size: 1.6rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.pricelist-list .pricelist__item.featured {
  transform: scale(1.07);
  z-index: 1;
}
.pricelist-list .pricelist__item .pricelist-tags-list {
  display: flex;
  justify-content: center;
}
.pricelist-list .pricelist__item .pricelist-tags-list .pricelist-tag__item {
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 600;
  margin-right: 1rem;
}
.pricelist-list .pricelist__item .pricelist-tags-list .pricelist-tag__item .s {
  font-size: 1.4rem;
}
.pricelist-list .pricelist__item .pricelist-tags-list .pricelist-tag__item:last-of-type {
  margin-right: 0;
}
.pricelist-list .pricelist__item .pricelist__name h3 {
  font-size: 2rem;
}
.pricelist-list .pricelist__item .pricelist__price {
  margin: 2rem 0 3rem 0;
  padding-bottom: 2rem;
  border-bottom: 1px solid #E6E6E6;
}
.pricelist-list .pricelist__item .pricelist__price .price__discount {
  font-weight: 700;
  color: #A0A0A0;
}
.pricelist-list .pricelist__item .pricelist__price h4 {
  font-size: 3rem;
  font-weight: 700 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricelist-list .pricelist__item .pricelist__features {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 3rem;
  text-align: left;
}
.pricelist-list .pricelist__item .pricelist__features > li {
  margin-bottom: 2rem;
  padding-top: 1px;
  padding-left: 32px;
  position: relative;
}
.pricelist-list .pricelist__item .pricelist__features > li:before {
  content: "";
  background: url("/img/pricelist-list-icon.svg");
  background-size: contain;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: 0;
}
.pricelist-list .pricelist__item .pricelist__features > li:last-of-type {
  margin-bottom: 0;
}
.pricelist-list .pricelist__item .pricelist__features > li ul {
  padding-left: 20px;
  padding-top: 5px;
}
.pricelist-list .pricelist__item .pricelist__features > li ul li {
  margin-bottom: 5px;
  list-style-type: disc;
}
.pricelist-list .pricelist__item .pricelist__features > li ul li:last-of-type {
  margin-bottom: 0;
}
.pricelist-list .pricelist__item .pricelist__desc {
  margin-bottom: 3rem;
}
.pricelist-list .pricelist__item .pricelist__desc p {
  font-size: 1.4rem;
}

@media (max-width: 768px) {
  .pricelist-list {
    display: flex;
    flex-direction: column;
  }
  .pricelist-list .pricelist__item {
    margin-right: 0;
    margin-bottom: 2rem;
    padding: 3rem 4rem;
  }
  .pricelist-list .pricelist__item.featured {
    transform: scale(1);
  }
  .pricelist-list .pricelist__item.featured:after {
    font-size: 1.4rem;
  }
  .pricelist-list .pricelist__item .pricelist-tags-list .pricelist-tag__item {
    font-size: 1.4rem;
  }
  .pricelist-list .pricelist__item .pricelist__name h3 {
    font-size: 1.6rem;
  }
  .pricelist-list .pricelist__item .pricelist__price {
    margin: 3rem 0 2rem 0;
  }
  .pricelist-list .pricelist__item .pricelist__price h4 {
    font-size: 3.6rem;
  }
  .pricelist-list .pricelist__item .pricelist__features {
    margin-bottom: 4rem;
  }
  .pricelist-list .pricelist__item .pricelist__features li:before {
    width: 22px;
    height: 22px;
    background-size: cover;
  }
  .pricelist-list .pricelist__item .pricelist__desc {
    margin-bottom: 3rem;
  }
}
.program {
  /*.program-map-container {
    display: flex;
    flex-wrap: wrap;

    .program-map__item {
      flex-basis: 33.3%;
      flex-shrink: 0;
      position: relative;

      .program-map__img {
        height: 250px;
        overflow: hidden;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .program-map__name {
        position: absolute;
        bottom: 2rem;
        right: 2rem;

        h3 {
          color: red;
          font-size: 2rem;
        }
      }
    }
  }*/
}
.program .program-container {
  padding-left: 10rem;
  display: flex;
  flex-wrap: wrap;
}
.program .program-container .program__item {
  width: calc(50% - 5rem);
  margin-right: 10rem;
  margin-bottom: 5rem;
}
.program .program-container .program__item:last-of-type {
  margin-right: 0;
}
.program .program-container .program__item:nth-of-type(2n) {
  margin-right: 0;
}
.program .program-container .program__item:only-of-type {
  flex-basis: 100%;
}
.program .program-container .program__item:only-of-type .program__heading {
  display: none;
}
.program .program-container .program__item:only-of-type .program__time {
  flex-basis: calc(15% - 2rem) !important;
}
.program .program-container .program__item:only-of-type .program__content {
  flex-basis: 85% !important;
}
.program .program-container .program__item:only-of-type .program__more {
  margin-left: 15% !important;
}
.program .program-container .program__item .program__heading h3 {
  text-align: left;
  font-size: 2.4rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #E6E6E6;
}
.program .program-container .program__item .program__section .program__row {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #F4F4F4;
  padding: 1.5rem 0;
}
.program .program-container .program__item .program__section .program__row:last-of-type {
  border-bottom: none;
}
.program .program-container .program__item .program__section .program__row .program__time {
  flex-basis: calc(35% - 2rem);
  font-size: 1.6rem;
  font-weight: 700;
  margin-right: 2rem;
}
.program .program-container .program__item .program__section .program__row .program__content {
  font-size: 1.8rem;
  flex-basis: 65%;
}
.program .program-container .program__item .program__section .program__row .program__content p, .program .program-container .program__item .program__section .program__row .program__content ul {
  margin-top: 1rem;
  font-size: 1.6rem;
  line-height: 2rem;
}
.program .program-container .program__item .program__section .program__row .program__content ul {
  padding-left: 2rem;
}
.program .program-container .program__item .program__section .program__row .program__content ul li {
  margin-bottom: 1rem;
}
.program .program-container .program__item .program__section .program__row .program__content ul li:last-of-type {
  margin-bottom: 0;
}
.program .program-container .program__item .program__section .program__row .program__more {
  width: 100%;
  margin-top: 2rem;
  margin-left: 35%;
}
.program .program-container .program__item .program__section .program__row .program__more__content {
  margin-top: 1rem;
  padding: 0 3rem;
  border-radius: 4px;
  background: #F4F4F4;
  overflow: hidden;
  height: 0;
}
.program .program-container .program__item .program__section .program__row .program__more__content.opened {
  height: 100%;
  width: 100%;
}
.program .program-container .program__item .program__section .program__row .program__more__content p {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}
.program .program-container .program__item .program__section .program__row .program__more__content p:last-of-type {
  margin-bottom: 2rem;
}
.program .program-container .program__item .program__section .program__row .program__more__content p:first-of-type {
  margin-top: 2rem;
}
.program .program-container .program__item .program__section .program__row .program__more__content li {
  margin-bottom: 2rem;
  font-size: 1.4rem;
  line-height: 2rem;
}
.program .program-container .program__item .program__section .program__row .program__more__content a {
  color: #1e3953;
  font-weight: 700;
}

@media (max-width: 768px) {
  .program {
    /*.program-map-container {
      display: flex;
      flex-wrap: wrap;

      .program-map__item {
        flex-basis: 33.3%;
        flex-shrink: 0;
        position: relative;

        .program-map__img {
          height: 250px;
          overflow: hidden;
          position: relative;

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .program-map__name {
          position: absolute;
          bottom: 2rem;
          right: 2rem;

          h3 {
            color: red;
            font-size: 2rem;
          }
        }
      }
    }*/
  }
  .program .program-container {
    padding-left: 0;
    flex-direction: column;
  }
  .program .program-container .program__item {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 3rem;
  }
  .program .program-container .program__item:last-of-type {
    margin-bottom: 0;
  }
  .program .program-container .program__item .program__heading h3 {
    font-size: 1.6rem;
  }
  .program .program-container .program__item .program__section .program__row .program__time {
    font-size: 1.4rem;
  }
  .program .program-container .program__item .program__section .program__row .program__content {
    font-size: 1.4rem;
  }
  .program .program-container .program__item .program__section .program__row .program__more__content {
    padding: 0 2rem;
  }
  .program .program-container .program__item .program__section .program__row .program__more__content p {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .program .program-container .program__item .program__section .program__row .program__more__content p:last-of-type {
    margin-bottom: 2rem;
  }
  .program .program-container .program__item .program__section .program__row .program__more__content p:first-of-type {
    margin-top: 2rem;
  }
}
.highlight:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 0 #00a99d;
  transform: scale(1);
  animation: pulse 1.5s forwards;
  animation-iteration-count: 2;
  border-radius: 6px;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 169, 157, 0.5);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 20px rgba(0, 169, 157, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 169, 157, 0);
  }
}

.address .input-container:first-of-type {
  flex-grow: 2;
}
.address .input-container:last-of-type {
  flex-grow: 1;
}

.register {
  display: flex;
  flex-wrap: wrap;
}
.register .reg-form-container {
  display: flex;
  flex-direction: column;
  width: 46%;
  margin-left: 27%;
}
.register .reg-form__block {
  position: relative;
  margin-top: 6rem;
}
.register .reg-form__block:first-of-type {
  margin-top: 0;
}
.register .reg-form__block .reg-form__no {
  width: 70px;
  height: 70px;
  background: #E6E6E6;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  left: -115px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.register .reg-form__block .reg-form__no span {
  font-size: 2.8rem;
  font-weight: 700;
  color: #A0A0A0;
}
.register .reg-form__block:after {
  content: "";
  position: absolute;
  left: -80px;
  top: 0;
  width: 3px;
  height: calc(100% + 7rem);
  background: #E6E6E6;
}
.register .reg-form__block:last-of-type {
  margin-bottom: 0;
}
.register .reg-form__block:last-of-type:after {
  display: none;
}
.register .reg-form__block h3 {
  margin-bottom: 1rem;
}
.register .reg-form__block .reg-form__cart {
  border-radius: 5px;
}
.register .reg-form__block .reg-form__cart .cart__body {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #E6E6E6;
  overflow: hidden;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}
.register .reg-form__block .reg-form__cart .cart__body .cart__row {
  border-bottom: 1px solid #E6E6E6;
}
.register .reg-form__block .reg-form__cart .cart__body .cart__row:last-of-type {
  border-bottom: 0;
  background: #F4F4F4;
}
.register .reg-form__block .reg-form__cart .cart__body .cart__row:last-of-type .cart__name {
  font-weight: 600;
  font-size: 1.5rem;
}
.register .reg-form__block .reg-form__cart .cart__footer {
  background: #F4F4F4;
  border-radius: 0 0 5px 5px;
}
.register .reg-form__block .reg-form__cart .cart__row {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
}
.register .reg-form__block .reg-form__cart .cart__row .cart__column {
  width: 33.3%;
}
.register .reg-form__block .reg-form__cart .cart__row .cart__column .cart__name {
  font-size: 1.3rem;
}
.register .reg-form__block .reg-form__cart .cart__row .cart__column .cart__email {
  color: #A0A0A0;
  font-size: 1rem;
}
.register .reg-form__block .reg-form__cart .cart__row .cart__column .cart__price {
  font-size: 1.6rem;
  font-weight: 600;
}
.register .reg-form__block.featured:before {
  content: "";
  position: absolute;
  top: -2rem;
  left: -2rem;
  width: calc(100% + 4rem);
  height: calc(100% + 3rem);
  border-radius: 5px;
  border: 1px solid #000;
  background: transparent;
  z-index: 0;
}

@media (max-width: 1600px) {
  .register .wrapper {
    width: 100%;
    margin: auto;
  }
}
@media (max-width: 768px) {
  .register .reg-form-container {
    width: 100%;
    margin-left: 0;
  }
  .register .reg-form-container .reg-form__block {
    padding-bottom: 3rem;
    margin-top: 2rem;
  }
  .register .reg-form-container .reg-form__block h3 {
    margin-left: 4rem;
    margin-bottom: 3rem;
  }
  .register .reg-form-container .reg-form__block .reg-form__no {
    width: 30px;
    height: 30px;
    top: -5px;
    left: 0;
  }
  .register .reg-form-container .reg-form__block .reg-form__no span {
    font-size: 1.8rem;
  }

  .form__name {
    font-size: 1.4rem;
  }

  .register .wrapper {
    width: 100%;
    margin-left: 0;
  }
  .register .register-form__container {
    flex-direction: column;
  }
  .register .register-form__column {
    flex-basis: 100%;
  }
  .register .register-form__column.featured:before {
    display: none;
  }
  .register .register-form__column.mr {
    margin-right: 0;
  }
  .register .register-form__column .btn {
    margin-top: 2rem;
  }
}
.map {
  height: 650px;
  margin-top: 0 !important;
  padding-top: 5rem !important;
  background: #fff;
}
.map.s {
  height: 500px;
}
.map.s .map-container {
  height: 500px;
}
.map .map-container {
  height: 650px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.map .map-container.s {
  height: 450px;
}
.map .map-detail-container {
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  color: #000;
  width: 400px;
  min-height: 500px;
}
.map .map-detail-container .map-detail__img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 200px;
}
.map .map-detail-container .map-detail__content {
  margin-top: 200px;
  padding: 3rem;
}
.map .map-detail-container .map-detail__content h2 {
  font-size: 2.2rem;
  margin: 0 0 1rem 0;
}
.map .map-detail-container .map-detail__content h3 {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.map .map-detail-container .map-detail__content h3 img {
  height: 2rem;
  margin-right: 1rem;
}
.map .map-detail-container .map-detail__content h3 span {
  font-size: 1.5rem;
  font-weight: 300;
  text-align: left;
}
.map .map-detail-container .map-detail__content p {
  font-size: 1.4rem;
  line-height: 2.2rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .map {
    padding-bottom: 2rem !important;
    height: 950px;
  }
  .map .wrapper {
    height: 100%;
  }
  .map.s {
    height: auto;
    padding-top: 0 !important;
  }
  .map .map-column {
    width: 100%;
    margin: 0 0 2rem 0 !important;
  }
  .map .map-column:last-of-type {
    margin-bottom: 0;
  }
  .map .map-container {
    height: 450px;
  }
  .map .map-detail-container {
    margin-left: 1rem;
    margin-right: 1rem;
    width: calc(100% - 2rem) !important;
    top: unset;
    bottom: 0;
  }
}
.contact-form {
  width: 100%;
  margin-left: 0;
  padding-left: 10rem;
}
.contact-form.register {
  width: 40%;
  margin-left: 30%;
}

@media (max-width: 768px) {
  .contact-form {
    margin-left: 0;
    width: 100%;
  }
  .contact-form.conference {
    padding-left: 0;
  }
}
.conference-header {
  min-height: 80px;
  max-height: 1080px;
  padding: 0 !important;
  background: #fff;
  overflow: hidden;
  position: relative;
  margin-bottom: 5rem;
}
.conference-header .header__nav {
  background: rgba(255, 255, 255, 0.98);
  border-bottom: 1px solid #E6E6E6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
}
.conference-header .header__nav.nav--bg {
  background: rgba(255, 255, 255, 0.98);
  border-bottom: 1px solid #E6E6E6;
}
.conference-header .header__nav.nav--bg a {
  color: #000 !important;
}
.conference-header .header__nav.nav--bg .logo__dark {
  display: block;
}
.conference-header .header__nav.nav--bg .logo__white {
  display: none;
}
.conference-header .header__nav.nav--bg .social-icons.white {
  display: none !important;
}
.conference-header .header__nav.nav--bg .social-icons.dark {
  display: flex !important;
}
.conference-header .header__nav .logo__dark {
  display: none;
}
.conference-header .header__nav .logo__white {
  display: block;
}
.conference-header .header__nav .social-icons.white {
  display: flex !important;
}
.conference-header .header__nav .social-icons.dark {
  display: none !important;
}
.conference-header .header__nav .header__nav__inner {
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding: 0;
}
.conference-header .header__nav .header__nav__inner .nav__column .nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.conference-header .header__nav .header__nav__inner .nav__column .nav__list .nav__item a {
  font-weight: 700;
  font-size: 1.6rem;
  padding: 0 2rem;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
}
.conference-header .header__nav .header__nav__inner .nav__column .nav__list .nav__item a:hover {
  text-decoration: none;
}
.conference-header .header__nav .header__nav__inner .nav__column.logo {
  padding: 2rem 0;
}
.conference-header .header__nav .header__nav__inner .nav__column.logo img {
  height: 100%;
}
.conference-header .header__nav .header__nav__inner .nav__column.back {
  display: flex;
  align-items: center;
}
.conference-header .header__nav .header__nav__inner .nav__column.back .nav__list {
  margin-left: 0;
}
.conference-header .header__nav .header__nav__inner .nav__column.back .nav__list a {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
}
.conference-header .header__nav .header__nav__inner .nav__column.back .nav__list a img {
  margin-right: 2rem;
  width: 20px;
}
.conference-header .header__nav .header__nav__inner .nav__column.navbar {
  display: flex;
  align-items: center;
  margin-right: 11rem;
}
.conference-header .header__nav .header__nav__inner .nav__column.navbar .nav__list {
  height: 100%;
}
.conference-header .header__nav .header__nav__inner .nav__column.navbar .nav__list .nav__item a {
  height: 100%;
  display: flex;
  align-items: center;
}
.conference-header .header__nav .header__nav__inner .nav__column.navbar .nav__list .nav__item.active {
  box-shadow: inset 0 -4px 0 #FEC601;
}
.conference-header .header__nav .header__nav__inner .nav__column.navbar .nav__list .nav__item:hover {
  box-shadow: inset 0 -4px 0 #FEC601;
}
.conference-header .header__nav .header__nav__inner .nav__column.social {
  display: flex;
  align-items: center;
}
.conference-header .header__nav .header__nav__inner .nav__column.social .social-icons {
  display: flex;
}
.conference-header .header__nav .header__nav__inner .nav__column.social .social-icons .social__icon {
  margin-right: 1rem;
}
.conference-header .header__nav .header__nav__inner .nav__column.social .social-icons .social__icon img {
  width: 30px;
}
.conference-header .header__nav .header__nav__inner .nav__column.social .social-icons .social__icon:last-of-type {
  margin-right: 0;
}
.conference-header .header__content {
  position: relative;
  z-index: 1;
  width: 60%;
  height: 100vh;
  max-height: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.conference-header .header__content .header-read__more {
  position: absolute;
  left: 0;
  bottom: 60px;
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
}
.conference-header .header__content .header-read__more:after {
  content: "";
  width: 1px;
  height: 60px;
  background: #E6E6E6;
  position: absolute;
  left: 17px;
  top: 34px;
  z-index: 0;
}
.conference-header .header__content .header-read__more img {
  margin-right: 1rem;
}
.conference-header .header__content .header-read__more p {
  font-size: 1.4rem;
}
.conference-header .header__content .header-read__more p:hover {
  text-decoration: underline;
}
.conference-header .header__content .header-tags-list {
  margin-bottom: 2rem;
}
.conference-header .header__content .header-tags-list .header-tags__item {
  font-size: 1.6rem;
  text-transform: uppercase;
}
.conference-header .header__content h1 {
  position: relative;
  margin-bottom: 7rem;
  width: 100%;
  font-size: 4.8rem;
  text-transform: uppercase;
}
.conference-header .header__content h1:after {
  content: "";
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background: #FEC601;
  position: absolute;
  left: 0;
  bottom: -5rem;
}
.conference-header .header__content .header__date {
  font-size: 2.8rem;
  margin-bottom: 1rem;
  font-weight: 700;
}
.conference-header .header__content .header__place {
  font-size: 1.6rem;
  margin-bottom: 6rem;
}
.conference-header .header__content a {
  align-self: flex-start;
}
.conference-header .header-img--bg {
  position: absolute;
  right: -55%;
  bottom: 30px;
  z-index: 0;
}

@media (max-width: 768px) {
  .conference-header {
    margin-bottom: 0;
  }
  .conference-header .header__nav {
    background: #fff;
    border-bottom: 1px solid #E6E6E6;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
  }
  .conference-header .header__nav .header__nav__inner {
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 1rem 0;
  }
  .conference-header .header__nav .header__nav__inner .nav__group {
    justify-content: space-between;
  }
  .conference-header .header__nav .header__nav__inner .nav__column .nav__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  .conference-header .header__nav .header__nav__inner .nav__column .nav__list .nav__item a {
    font-weight: 700;
    font-size: 1.4rem;
    padding: 0 1rem;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
  }
  .conference-header .header__nav .header__nav__inner .nav__column .nav__list .nav__item a:hover {
    text-decoration: none;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.logo {
    padding: 0.5rem 0;
    width: 150px;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.logo img {
    height: 100%;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.back {
    display: flex;
    align-items: center;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.back .nav__list {
    margin-left: 1rem;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.back .nav__list a {
    display: flex;
    align-items: center;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.back .nav__list a p {
    opacity: 0.3;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.back .nav__list a img {
    margin-right: 2rem;
    width: 20px;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.navbar {
    display: flex;
    align-items: center;
    margin-right: 0;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: #F4F4F4;
    border-bottom: 1px solid #E6E6E6;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.navbar .nav__list {
    width: 100%;
    justify-content: space-between;
    padding: 0 1rem;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.navbar .nav__list li {
    padding: 1rem 0;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.social {
    display: flex;
    align-items: center;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.social .social-icons {
    display: flex;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.social .social-icons .social__icon {
    margin-right: 1rem;
  }
  .conference-header .header__nav .header__nav__inner .nav__column.social .social-icons .social__icon:last-of-type {
    margin-right: 0;
  }
  .conference-header .header__content {
    width: 100%;
    height: 80vh;
    margin-top: 7rem;
  }
  .conference-header .header__content .header-read__more {
    display: none;
  }
  .conference-header .header__content .header-tags-list {
    margin-bottom: 2rem;
  }
  .conference-header .header__content .header-tags-list .header-tags__item {
    font-size: 2rem;
  }
  .conference-header .header__content h1 {
    margin-bottom: 4rem;
    font-size: 3.2rem;
  }
  .conference-header .header__content h1:after {
    height: 24px;
    width: 24px;
    bottom: -3rem;
  }
  .conference-header .header__content .header__date {
    font-size: 2.6rem;
  }
  .conference-header .header__content .header__place {
    font-size: 2rem;
    margin-bottom: 4rem;
  }
  .conference-header .header-img--bg {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    width: 200px;
  }
}
.faq-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10rem;
}
.faq-container .faq__item {
  display: flex;
  width: 100%;
  padding: 2rem 0;
  border-bottom: 1px solid #E6E6E6;
}
.faq-container .faq__item:first-of-type {
  padding-top: 0;
}
.faq-container .faq__item .faq__column:first-of-type {
  flex-basis: 80%;
}
.faq-container .faq__item .faq__column:last-of-type {
  flex-basis: 20%;
  display: flex;
  justify-content: flex-end;
}
.faq-container .faq__item h3 {
  font-size: 1.6rem;
  margin-bottom: 0;
  text-align: left;
}
.faq-container .faq__item .faq__content {
  height: 20px;
  overflow: hidden;
}
.faq-container .faq__item .faq__content p {
  font-size: 1.6rem;
}
.faq-container .faq__item .faq__content.opened {
  height: 100%;
}

@media (max-width: 768px) {
  .faq-container {
    margin-left: 0;
  }
  .faq-container .faq__item h3 {
    font-size: 1.4rem;
  }
  .faq-container .faq__item .faq__content {
    height: 20px;
  }
  .faq-container .faq__item .faq__content p {
    font-size: 1.4rem;
  }
}
.organizator-container {
  margin-left: 10rem;
  margin-bottom: 4rem;
}
.organizator-container p {
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
.organizator-container p:last-of-type {
  margin-bottom: 0;
}

.accomodation-container {
  display: flex;
  padding-left: 10rem;
}
.accomodation-container .accomodation__item {
  width: 50%;
  margin-right: 2rem;
}
.accomodation-container .accomodation__item:last-of-type {
  margin-right: 0;
}
.accomodation-container .accomodation__item h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.accomodation-container .accomodation__item h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 600;
  text-align: left;
}
.accomodation-container .accomodation__item p, .accomodation-container .accomodation__item li {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2.2rem;
}
.accomodation-container .accomodation__item a {
  color: #1e3953;
  font-weight: 600;
  text-decoration: underline;
}
.accomodation-container .accomodation__item a:hover {
  text-decoration: none;
}

.map-marker {
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  display: flex;
}
.map-marker:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: calc(50% - 8px);
  bottom: calc(100% - 3px);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #fff;
  z-index: 1;
}
.map-marker:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  border: 4px solid #fff;
  z-index: 2;
  box-sizing: border-box;
}
.map-marker .img-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
}
.map-marker .img-container img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.teaser h2 {
  font-size: 3rem;
  text-transform: none;
}
.teaser .teaser-block {
  margin-bottom: 4rem;
}
.teaser .teaser-block.text {
  width: 75%;
}
.teaser .teaser-block.text a {
  font-weight: bold;
  color: #1e3953;
}
.teaser .teaser-block.text p, .teaser .teaser-block.text li {
  font-size: 1.8rem;
  line-height: 2.6rem;
}
.teaser .teaser-block.text p {
  margin-top: 1.6rem;
}
.teaser .teaser-block.text p:first-of-type {
  margin-top: 0;
}
.teaser .teaser-block.text ul {
  padding-left: 32px;
  list-style: none;
  margin-top: 1.6rem;
}
.teaser .teaser-block.text ul li {
  position: relative;
  margin-bottom: 0.6rem;
}
.teaser .teaser-block.text ul li:before {
  content: "";
  position: absolute;
  left: -26px;
  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #FEC601;
}
.teaser .teaser-block.img img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
}
.teaser-desc {
  margin-top: -6rem;
  z-index: 1;
}

@media (max-width: 768px) {
  .teaser h2 {
    font-size: 3rem;
    text-transform: none;
  }
  .teaser .teaser-block.text {
    width: 100%;
  }
  .teaser .teaser-block.text p, .teaser .teaser-block.text li {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
  .teaser .teaser-block.text p {
    margin-top: 1.6rem;
  }
  .teaser .teaser-block.text ul {
    margin-top: 1.6rem;
  }
  .teaser-desc {
    margin-top: 0;
  }
}