.header {
  min-height: 80px;
  max-height: 1080px;
  padding: 0 !important;
  background: $color-white;
  overflow: unset;
  position: relative;
  //margin-bottom: 5rem;

  &.o--h {
    overflow: hidden;
  }

  .header__nav {
    background: rgba(255, 255, 255, .98);
    border-bottom: 1px solid $color-bright;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    &.nav--bg {

      .btn--text {
        color: $color-black;
      }
    }

    .header__nav__inner {
      display: flex;
      justify-content: space-between;
      height: 80px;
      padding: 0;

      .nav__column {

        .nav__list {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;

          .nav__item {

            a {
              font-weight: 700;
              font-size: 1.6rem;
              padding: 0 2rem;
              color: $color-black;
              text-transform: uppercase;
              text-decoration: none;

              &:hover {
                text-decoration: none;
              }
            }
          }
        }

        &.logo {
          padding: 2rem 0;

          img {
            height: 100%;
          }
        }

        &.back {
          display: flex;
          align-items: center;

          .nav__list {
            margin-left: 0;

            a {
              height: 100%;
              display: flex;
              align-items: center;
              font-size: 1.4rem;

              img {
                margin-right: 2rem;
                width: 20px;
              }
            }
          }
        }

        &.navbar {
          display: flex;
          align-items: center;
          margin-right: 11rem;

          .nav__list {
            height: 100%;

            .nav__item {


              a {
                height: 100%;
                display: flex;
                align-items: center;
              }

              &.active {
                box-shadow: inset 0 -4px 0 $color-secondary;
              }

              &:hover {
                box-shadow: inset 0 -4px 0 $color-secondary;
              }
            }
          }
        }

        &.account {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: relative;
          //width: 160px;

          .btn--nav {
            font-size: 1.3rem;
            padding: 1rem 2rem;
          }

          .nav__account-btn {
            font-size: 1.2rem;
            color: $color-black;
            margin-right: 2rem;
            padding-right: 2rem;
            display: flex;
            align-items: center;
            font-weight: 600;
            height: 100%;
            position: relative;

            &:after {
              content: '';
              width: 1px;
              height: 16px;
              position: absolute;
              left: 100%;
              top: calc(50% - 8px);
              background: $color-black;
            }

            &.active {
              box-shadow: inset 0 -4px 0 $color-secondary;
            }

            svg {
              fill: $color-black;
              height: 16px;
            }
          }

          .nav__logout-btn {
            height: 16px;
          }
        }

        &.social {

          display: flex;
          align-items: center;

          .social-icons {
            display: flex;

            .social__icon {
              margin-right: 1rem;

              img {
                width: 30px;
              }

              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  .header__content {
    position: relative;
    z-index: 1;
    width: 55%;
    height: 100vh;
    max-height: 1080px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.s {
      height: auto;
      min-height: 650px;
      padding-top: 80px;
    }

    .header-read__more {
      position: absolute;
      left: 0;
      bottom: 60px;
      display: flex;
      align-items: center;
      color: $color-black;
      text-decoration: none;

      &:after {
        content: '';
        width: 1px;
        height: 60px;
        background: $color-bright;
        position: absolute;
        left: 17px;
        top: 34px;
        z-index: 0;
      }

      img {
        margin-right: 1rem;
      }

      p {
        font-size: 1.4rem;

        &:hover {
          text-decoration: underline;
        }

      }
    }

    .header-tags-list {
      margin-bottom: 2rem;

      .header-tags__item {
        font-size: 1.6rem;
        text-transform: uppercase;
      }
    }

    h1 {
      position: relative;
      margin-bottom: 7rem;
      width: 100%;
      font-size: 4.4rem;
      text-transform: uppercase;

      &.s {
        font-size: 3.4rem;
      }

      &.no--dot {

        &:after {
          display: none;
        }
      }

      &:after {
        content: '';
        height: 34px;
        width: 34px;
        border-radius: 50%;
        background: $color-secondary;
        position: absolute;
        left: 0;
        bottom: -5rem;
      }
    }

    .header__date {
      font-size: 2.8rem;
      margin-bottom: 1rem;
      font-weight: 700;
    }

    .header__place {
      font-size: 1.8rem;
      line-height: 2.6rem;
      margin-bottom: 6rem;
    }

    a {
      align-self: flex-start;
    }
  }

  .header-img--bg {
    position: absolute;
    left: calc(50% + 200px);
    bottom: 30px;
    width: 1000px;
    height: 1000px;
    z-index: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 120px;
      left: -200px;
      background: $color-light;
      width: 550px;
      height: 550px;
      border-radius: 50%;
      z-index: -1;
    }
  }

  .header-s-img--bg {
    position: absolute;
    right: 0;
    top: 150px;
    width: 450px;
    height: 450px;
    z-index: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 10px;
      right: 10px;
      background: $color-secondary;
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: -50px;
      right: 100px;
      background: $color-primary;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      z-index: 1;
    }

  }


}

@media (max-width: 768px) {

  .header {
    margin-bottom: 0;
    min-height: 60px;
    max-height: unset;
    overflow: hidden;

    .header__nav {
      background: $color-white;
      border-bottom: 1px solid $color-bright;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 99999;

      .header__nav__inner {
        display: flex;
        justify-content: space-between;
        height: 60px;
        padding: 1rem 0;

        .nav__group {
          justify-content: space-between;
        }

        .nav__column {

          .nav__list {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;

            .nav__item {

              a {
                font-weight: 700;
                font-size: 1.2rem;
                padding: 0 .5rem;
                color: $color-black;
                text-transform: uppercase;
                text-decoration: none;

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }

          &.logo {
            padding: .5rem 0;
            width: 150px;

            img {
              height: 100%;
            }
          }

          &.back {
            display: flex;
            align-items: center;

            .nav__list {
              margin-left: 1rem;

              a {
                display: flex;
                align-items: center;

                p {
                  opacity: .3;
                }

                img {
                  margin-right: 2rem;
                  width: 20px;
                }
              }
            }
          }

          &.navbar {
            display: flex;
            align-items: center;
            margin-right: 0;
            position: absolute;
            top: 60px;
            left: 0;
            width: 100%;
            background: $color-light;
            border-bottom: 1px solid $color-bright;

            .nav__list {
              width: 100%;
              justify-content: space-between;
              padding: 0 1rem;

              li {
                padding: 1rem 0;
              }
            }
          }

          /*&.social {

              display: flex;
              align-items: center;

              .social-icons {
                  display: flex;

                  .social__icon {
                      margin-right: 1rem;

                      &:last-of-type {
                          margin-right: 0;
                      }
                  }
              }
          }*/
        }
      }
    }

    .header__content {
      width: 100%;
      height: 80vh;
      min-height: unset !important;
      margin-top: 7rem;

      .header-read__more {
        display: none;
      }

      .header-tags-list {
        margin-bottom: 2rem;

        .header-tags__item {
          font-size: 1.6rem;
        }
      }

      h1 {
        margin-bottom: 4rem;
        font-size: 3.2rem;

        &:after {
          height: 24px;
          width: 24px;
          bottom: -3rem;
        }
      }

      .header__date {
        font-size: 2.6rem;
      }

      .header__place {
        font-size: 1.6rem;
        margin-bottom: 3rem;
      }
    }

    .header-img--bg {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
      width: 200px;
    }


    .header-s-img--bg {
      position: absolute;
      right: -20px;
      top: 50px;
      width: 150px;
      height: 150px;

      &:after {
        content: '';
        position: absolute;
        top: 10px;
        right: 10px;
        background: $color-secondary;
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: -5px;
        right: 100px;
        background: $color-primary;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        z-index: 1;
      }

    }
  }
}
